import React from 'react'
import { Link, useRouteMatch, NavLink } from 'react-router-dom'
import { useFirebaseConnect } from 'react-redux-firebase'

import TDicon from '../../assets/svg/the-drobe-logomark.svg'

import { PaletteIcon } from '../Icons/PaletteIcon'
import { WardrobeIcon } from '../Icons/WardrobeIcon'
import { HangerIcon } from '../Icons/HangerIcon'
// import { EllipsisVerticalIcon } from '../Icons/EllipsisVerticalIcon'
import { NavItem } from './NavItem'
import { DB_PATHS } from '../../constants'
import { useSelector } from '../../hooks/useSelector'
import { selectUid } from '../../store/auth/selectors'
import { Avatar } from './Avatar'
import { ROUTE_PATHS } from '../Routes'

const items = [
  { tooltip: 'Palettes', to: '/palettes', cmp: <PaletteIcon /> },
  { tooltip: 'Wardrobe', to: '/wardrobe', cmp: <WardrobeIcon /> },
  { tooltip: 'Outfits', to: '/outfits', cmp: <HangerIcon /> },
]

export const Header = () => {
  const matchLogin = useRouteMatch(ROUTE_PATHS.LOGIN)
  const matchSignUp = useRouteMatch(ROUTE_PATHS.SIGN_UP)
  const matchWelcome = useRouteMatch(ROUTE_PATHS.WELCOME)
  const uid = useSelector(selectUid)
  useFirebaseConnect(uid ? [{ path: `${DB_PATHS.SUBSCRIPTIONS}/${uid}` }] : [])

  if (!matchLogin?.isExact && !matchSignUp?.isExact && !matchWelcome?.isExact) {
    return (
      <header className="header">
        <Link to="/">
          <img src={TDicon} alt="The 'drobe logomark" />
        </Link>
        <nav className="navigation">
          <ul className="navigation-items">
            {items.map(({ tooltip, to, cmp }, i) => (
              <NavItem key={i} tooltip={tooltip}>
                <NavLink className="navigation-item" to={to}>
                  {cmp}
                </NavLink>
              </NavItem>
            ))}
          </ul>
        </nav>
        <div className="utility-navigation">
          <Avatar />
          {/* <NavItem tooltip={'Settings'}>
            <a href="#" className="utility-navigation-item">
              <EllipsisVerticalIcon />
            </a>
          </NavItem> */}
          {/* <span className="txt-td-pink ml-15 cursor-pointer" onClick={() => firebase.logout()}>
            Logout
          </span> */}
        </div>
      </header>
    )
  }

  return null
}
