import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ColourKeys } from '../../types'
import { ROUTE_PATHS } from '../Routes'
import { useQuery } from '../../hooks/useQuery'
import { useScrollIntoView } from '../../hooks/useScrollIntoView'

type Props = {
  backgroundColor: string
  paletteId: string
  blockId: ColourKeys
  disabled: boolean
}

export const BlockColour = ({ backgroundColor, blockId, paletteId, disabled }: Props) => {
  const { scrollRef, scrollRefIntoView } = useScrollIntoView()
  const style = { backgroundColor }
  const query = useQuery()

  const selectedClass =
    blockId === query.get('blockId') &&
    paletteId === query.get('id') &&
    'palette-card-block--selected'

  return (
    <div className={classNames(['palette-block', blockId, selectedClass])} style={style}>
      <Link
        ref={scrollRef}
        className={'colour-block'}
        to={
          disabled
            ? `${ROUTE_PATHS.PALETTES}`
            : `${ROUTE_PATHS.PALETTES}?id=${paletteId}&blockId=${blockId}`
        }
        onClick={scrollRefIntoView}
      />
    </div>
  )
}
