import React, { useEffect } from 'react'
import classNames from 'classnames'

import { WardrobeItemType } from '../../types'
import { WardrobeItemPlaceholder } from './WardrobeItemPlaceholder'
import { usePreloadImage } from '../../hooks/usePreloadImage'
import { useScrollIntoView } from '../../hooks/useScrollIntoView'
import { DeleteBtn } from '../DeleteBtn'

export interface WardrobeImageItemSelected {
  isSelected: boolean
  selectedItemId: string
  clearItem: boolean
}

type WardrobeImageItemOnClickHandler = (value: WardrobeImageItemSelected) => void

type Props = {
  itemId: string
  item: WardrobeItemType
  onClick?: WardrobeImageItemOnClickHandler | undefined
  disabled?: boolean
  selected: boolean
  margin?: 'sm' | 'lg'
}

export const WardrobeImageItem = ({
  disabled = false,
  selected = false,
  margin = 'lg',
  itemId,
  item: { image, category, colour },
  onClick,
}: Props) => {
  const { scrollRef, scrollRefIntoView } = useScrollIntoView<HTMLDivElement>()
  const { isImageLoading, hasImageFailed } = usePreloadImage(image)
  const catLowercase = category.toLowerCase()
  const imgSize =
    catLowercase === 'pants' || catLowercase === 'dresses' || catLowercase === 'outerwear'
      ? 'wardrobe-image--portrait'
      : 'wardrobe-image--square'
  const imgLoader = isImageLoading && 'wardrobe-image-loader bg-td-offwhite'
  const isDisabled = disabled && 'wadrobe-item--disabled'
  const m = margin === 'lg' ? 'm-15' : 'm-2'
  const isSelected = selected && 'wadrobe-item--selected'
  const handleOnClick = () =>
    onClick && onClick({ isSelected: selected, selectedItemId: itemId, clearItem: selected })

  useEffect(() => {
    let scrollToTimeOut: NodeJS.Timeout

    if (selected) {
      scrollToTimeOut = setTimeout(() => {
        scrollRefIntoView()
      }, 350)
    }

    return () => clearTimeout(scrollToTimeOut)
    // eslint-disable-next-line
  }, [])

  if (image && !hasImageFailed) {
    return (
      <div
        ref={scrollRef}
        onClick={() => !selected && handleOnClick()}
        className={classNames([
          'wadrobe-item wardrobe-image br-primary bs-secondary',
          'position-relative',
          imgSize,
          imgLoader,
          isSelected,
          isDisabled,
          m,
        ])}
        style={{
          backgroundImage: `url(${image?.url})`,
        }}
      >
        {isSelected && (
          <div className="wadrobe-item-btn position-absolute">
            <DeleteBtn size={'sm'} label={'Remove from outfit'} onClick={() => handleOnClick()} />
          </div>
        )}
        {isImageLoading && <WardrobeItemPlaceholder isLoading={isImageLoading} colour={colour} />}
      </div>
    )
  }

  return null
}
