import React from 'react'
import { Link } from 'react-router-dom'

const InviteAndEarn = () => (
  <>
    <br />
    <br />
    You can increase the limit by{' '}
    <Link to="/account/invite-and-earn">inviting your friends! 😉</Link>
  </>
)

const message = (val: number) => ({
  wardrobe: (
    <p>
      {`You've hit your item limit (${val}) for your free plan. If you want to save more items, upgrade your account through The 'drobe mobile app!`}
      <InviteAndEarn />
    </p>
  ),
  outfits: (
    <p>
      {`You've hit your outfit limit (${val}) for your free plan. If you want more outfits, upgrade your account through The 'drobe mobile app!`}
      <InviteAndEarn />
    </p>
  ),
  palettes: (
    <p>
      {`You've hit your palette limit (${val}) for your free plan. If you want more palettes, upgrade
      your account through The 'drobe mobile app!`}
      <InviteAndEarn />
    </p>
  ),
})

export const LIMIT_REACHED = {
  WARDROBE: 'WARDROBE',
  OUTFITS: 'OUTFITS',
  PALETTES: 'PALETTES',
}

export type LimitReachedType = 'WARDROBE' | 'OUTFITS' | 'PALETTES'

/**
 * Function that returns an object containing a title and a body key
 * based on the arg type passed
 * @param type LimitReachedType
 * @param {number} maxVal - max limit for the chosen type
 * @returns {Object} { title: string, body: string | JSX.Element }
 * @example limitReached(LIMIT_REACHED.WARDROBE, maxWardrobeItems)
 */
export const limitReached = (type: LimitReachedType, maxVal: number) => {
  const getText = () => {
    switch (type) {
      case LIMIT_REACHED.WARDROBE:
        return message(maxVal).wardrobe
      case LIMIT_REACHED.OUTFITS:
        return message(maxVal).outfits
      case LIMIT_REACHED.PALETTES:
      default:
        return message(maxVal).palettes
    }
  }

  return {
    title: 'Oops - no more space 🤷🏻‍♀️',
    body: getText(),
  }
}
