import { createSelector } from 'reselect'

import { AppState } from '..'
import { selectUid } from '../auth/selectors'

export const selectIsUserPremium = (state: AppState) => state.firebase.profile.isPremium

export const selectSubscriptionInfo = createSelector(
  selectUid,
  (state: AppState) => state.firebase.data?.subscriptions,
  (uid, subscriptions) => subscriptions && subscriptions[uid]
)
export const selectIsSubscriptionActive = createSelector(
  selectSubscriptionInfo,
  (subscription) => subscription?.isActive
)

export const makeSelectIsSubActive = createSelector(
  selectIsUserPremium,
  selectIsSubscriptionActive,
  (isPremium, isActive) => (isPremium ? isPremium : isActive)
)
