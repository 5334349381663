import React from 'react'
import classNames from 'classnames'

import TrashIcon from '../assets/svg/ui-icons/trash-gradient.svg'
import { ItemTooltip } from './ItemTooltip'

interface Props {
  label?: string
  size?: 'sm'
  onClick: () => void
}

export const DeleteBtn = ({ onClick, label = 'Delete', size }: Props) => (
  <ItemTooltip tooltip={label}>
    <div
      className={classNames([
        'round-btn bg-white flex jc-center',
        { 'round-btn--small': size === 'sm' },
      ])}
      onClick={onClick}
    >
      <img src={TrashIcon} alt="Delet Palette" title={label} />
    </div>
  </ItemTooltip>
)
