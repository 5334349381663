import { combineReducers } from 'redux'
import { AlertType } from '../../types'
import { UIActionTypes, UPDATE_ALERT } from './actionTypes'

const initialState: AlertType = {
  show: false,
  text: '',
  variant: 'success',
}

const alert = (state = initialState, action: UIActionTypes): AlertType => {
  switch (action.type) {
    case UPDATE_ALERT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export const uiReducer = combineReducers({
  alert,
})

export type UiReducer = ReturnType<typeof uiReducer>
