import React, { useState } from 'react'
import Fade from 'react-bootstrap/Fade'

type Props = {
  children: JSX.Element
  tooltip: string
}

export const NavItem = ({ children, tooltip }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <li
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className="navigation-list-item"
    >
      {children}

      <Fade in={showTooltip} appear unmountOnExit>
        <span className="item-tooltip">{tooltip}</span>
      </Fade>
    </li>
  )
}
