import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '../Routes'

const LINKS = [
  { to: ROUTE_PATHS.PALETTES, label: 'Palettes' },
  { to: ROUTE_PATHS.WARDROBE, label: 'Wardrobe' },
  { to: ROUTE_PATHS.OUTFITS, label: 'Outfits' },
]

export const NotFound = () => {
  return (
    <div className="section-container bg-offwhite flex flex-row">
      <div className="section-items flex flex-col ai-center">
        <div className="col-6">
          <div className="section-container-heading pl-50 pt-30 pr-50 pb-15 jc-center">
            <h1 className="flex ai-flex-start">{`That's not right 🤔`}</h1>
          </div>
          <div className="ml-50 mr-50 mt-15 p-30 bg-white br-primary bs-secondary txt-center">
            <h5>{`We couldn't find the resource you were looking for.`}</h5>
            <p>{`Try one of these links:`}</p>
            <ul className="list-group list-group-horizontal-sm jc-center">
              {LINKS.map(({ to, label }) => (
                <li key={label} className="list-group-item">
                  <Link to={to}>{label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
