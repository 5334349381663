import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isEmpty } from 'react-redux-firebase'

import { AppState } from '../../store'
import { useSelector } from 'react-redux'

type Props = {
  children: JSX.Element
  [key: string]: any
}

export const PrivateRoute = ({ children, ...rest }: Props) => {
  const auth = useSelector((state: AppState) => state.firebase.auth)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
