import React from 'react'

import TDlogo from '../assets/svg/the-drobe-logo-horizontal-variant.svg'
import { MobileAppDownloadBtns } from './MobileAppDownloadBtns'

export const MobileAppMessage = () => {
  return (
    <div className="bg-gradient mobile-app-message flex flex-col jc-center ai-center">
      <div className="col-11 bg-white p-50 br-primary bs-primary flex flex-col jc-center">
        <img className="mb-50" src={TDlogo} alt="The 'drobe logo - Horizontal variant" />
        <p className="txt-center mb-50">
          {`Hey there! To get the most out of The 'drobe, we recommend downloading the app by
          clicking one of those buttons below.`}
        </p>
        <MobileAppDownloadBtns />
      </div>
    </div>
  )
}
