import React from 'react'

import { getMobileOS, MOBILE_OS } from '../helpers'

import GooglePlayBtn from '../assets/svg/google-play.svg'
import AppStoreBtn from '../assets/svg/app-store.svg'

export const MobileAppDownloadBtns = () => {
  const os = getMobileOS()
  const iosUri =
    os === MOBILE_OS.IOS
      ? 'itms-apps://apps.apple.com/app/the-drobe/id1448655936?ls=1'
      : 'https://apps.apple.com/app/the-drobe/id1448655936?ls=1'
  const androidUri =
    os === MOBILE_OS.ANDROID
      ? 'market://details?id=com.thedrobe'
      : 'https://play.google.com/store/apps/details?id=com.thedrobe'

  return (
    <div className="flex flex-row jc-center">
      <a href={iosUri} className="mr-15">
        <img src={AppStoreBtn} alt="Download on the App Store" />
      </a>
      <a href={androidUri}>
        <img src={GooglePlayBtn} alt="Get in on Google Play" />
      </a>
    </div>
  )
}
