import React, { useState } from 'react'
import { useFirebaseConnect, isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import Alert from 'react-bootstrap/Alert'

import { useSelector } from '../../hooks/useSelector'
import { useSelector as useCustomSelector } from '../../hooks/useSel'
import { selectUid } from '../../store/auth/selectors'
import { DB_PATHS, MAX_PALETTES } from '../../constants'
import { AlertType, OrderedPalettes } from '../../types'
import { Palette } from './Palette'
import { Editor } from '../Editor'
import { AddBtn } from '../AddBtn'
import { defaultEmptyPalette } from '../../data/palettes'
import { EmptyState } from '../EmptyState'
import { Loader } from '../Loader'
import { limitReached, LIMIT_REACHED, LimitReachedType } from '../../helpers'
import { makeSelectIsSubActive } from '../../store/subscription/selectors'
import { CustomModal } from '../CustomModal'
import { ItemsCount } from '../ItemsCount'
import { selectUIAlert } from '../../store/ui/selectors'
import { selectOrderedPalettes } from '../../store/palettes/selectors'
import { selectReferralsInfo } from '../../store/referrals/selectors'

export const Palettes = () => {
  const [showModal, setShowModal] = useState(false)
  const firebase = useFirebase()
  const uid = useSelector(selectUid) as string
  const isSubActive = useSelector(makeSelectIsSubActive) as boolean
  const palettes = useSelector(selectOrderedPalettes) as OrderedPalettes
  const referral = useCustomSelector(selectReferralsInfo)
  const maxPalettes =
    referral && referral?.palettes ? MAX_PALETTES + referral.palettes : MAX_PALETTES

  const modalContent = limitReached(LIMIT_REACHED.PALETTES as LimitReachedType, maxPalettes)
  const alert = useSelector(selectUIAlert) as AlertType

  useFirebaseConnect([
    { path: `${DB_PATHS.PALETTES}/${uid}` },
    { path: `${DB_PATHS.REFERRALS}/${uid}` },
  ])

  const palettesUid = (palettes && palettes[uid]) || []

  const handleAddPalette = () => {
    if (!isSubActive && palettesUid.length >= maxPalettes) {
      return setShowModal(true)
    }
    firebase.push(`${DB_PATHS.PALETTES}/${uid}`, defaultEmptyPalette)
  }

  const renderCustomModal = () => (
    <CustomModal
      show={showModal}
      onModalClose={() => setShowModal(false)}
      onModalConfirm={() => setShowModal(false)}
      body={modalContent.body}
      title={modalContent.title}
      ctaLabel={'Got it'}
    />
  )

  const isPaletteDisabled = (index: number) => index + 1 > maxPalettes

  if (!isLoaded(palettes)) {
    return <Loader fullScreen />
  }

  if (isEmpty(palettesUid)) {
    return (
      <>
        {renderCustomModal()}
        <EmptyState onClick={handleAddPalette} />
      </>
    )
  }

  return (
    <>
      {renderCustomModal()}
      <div className="section-container bg-offwhite flex flex-row">
        <div className="section-items flex flex-col">
          <div className="section-container-heading pl-50 pt-30 pr-50 pb-15">
            <h1 className="flex ai-flex-start">
              Palettes
              {!isSubActive && (
                <ItemsCount
                  tooltip={'Palettes used with free plan'}
                  current={palettesUid.length}
                  max={maxPalettes}
                />
              )}
            </h1>
            <Alert className="m-0" variant={alert?.variant} show={alert?.show}>
              {alert?.text}
            </Alert>
            <AddBtn onClick={handleAddPalette} label={'Add palette'} />
          </div>
          <div className="pl-50 pr-50 flex flex-row flex-wrap jc-start">
            {palettesUid.map(({ key, value }, index) => {
              const isDisabled = isSubActive ? false : isPaletteDisabled(index)

              return <Palette key={key} id={key} item={value} disabled={isDisabled} />
            })}
          </div>
        </div>

        <Editor />
      </div>
    </>
  )
}
