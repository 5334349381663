import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import { firebaseConfig, rrfConfig } from './config'
import { configureStore } from './store'
import App from './App'
import './assets/scss/styles.scss'

const store = configureStore()

firebase.initializeApp(firebaseConfig)
firebase.analytics()

// if (process.env.NODE_ENV === 'development') {
//   firebase.auth().useEmulator(`http://localhost:9099`)
//   firebase.database().useEmulator('localhost', 9000)
//   firebase.storage().useEmulator('localhost', 9199)
// }

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
}

const Root = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>
)

const mountNode = document.getElementById('app')

ReactDOM.render(<Root />, mountNode)
