import React from 'react'

import { OutfitType, OutfitCategories, WardrobeVariants } from '../../types'
import { OutfitBlock } from './OutfitBlock'

interface Props {
  outfitId: string
  outfit: OutfitType
  code: OutfitCategories
}

type rowType = [WardrobeVariants, string | undefined][]

export const Outfit = ({ code, outfit: { items }, outfitId }: Props) => {
  if (!items) {
    return null
  }

  const rowOne: rowType = [
    ['OUTERWEAR', items.OUTERWEAR],
    ['SHOES', items.SHOES],
  ]
  const rowTwo: rowType = [
    ['TOP', items.TOP],
    ['PANTS_OR_DRESS', items.PANTS_OR_DRESS],
  ]
  const rowThree: rowType = [
    ['HAT', items.HAT],
    ['SUNGLASSES', items.SUNGLASSES],
    ['BAG', items.BAG],
    ['WATCH', items.WATCH],
    ['RING', items.RING],
  ]

  const renderOutfitBlockList = (list: rowType) => {
    return list.map(([variant, wardrobeItemId], i) => (
      <OutfitBlock
        key={i}
        code={code}
        outfitId={outfitId}
        variant={variant}
        wardrobeItemId={wardrobeItemId}
      />
    ))
  }

  return (
    <div className="outfit-card br-primary bs-secondary bg-white flex ai-center jc-center m-15">
      <div className="outfit-card-col">{renderOutfitBlockList(rowOne)}</div>
      <div className="outfit-card-col">{renderOutfitBlockList(rowTwo)}</div>
      <div className="outfit-card-col outfit-card-col--small">
        {renderOutfitBlockList(rowThree)}
      </div>
    </div>
  )
}
