import { useState, useEffect } from 'react'
import { validate } from 'email-validator'

export enum Field {
  Email,
  Password,
}

export const useValidateField = (type: Field, value: string) => {
  const [isValid, setIsValid] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  useEffect(() => {
    if (type === Field?.Email) {
      setIsValid(Boolean(value) && validate(value))
      setIsInvalid(Boolean(value) && !validate(value))
    }

    if (type === Field?.Password) {
      setIsInvalid(value.length < 6 && value !== '')
      setIsValid(value.length >= 6)
    }
  }, [type, value])

  return {
    isValid,
    isInvalid,
  }
}
