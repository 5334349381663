import { AppState } from '..'
import { createSelector } from 'reselect'
import { WardobeCategory, WardrobeItemType, WardrobeMapType } from '../../types'
import { selectUid } from '../auth/selectors'

export const selectOrderedWardrobe = (state: AppState) => state.firebase.ordered.wardrobe
export const selectDataWardrobe = (state: AppState) => state.firebase.data.wardrobe
export const makeSelectWardrobeItemByIdAndCat = (itemId: string, category: WardobeCategory) =>
  createSelector(selectUid, selectDataWardrobe, (uid, wardrobe) => wardrobe[uid][category][itemId])

export const makeSelectWardrobeItemById = (itemId: string) =>
  createSelector(selectUid, selectDataWardrobe, (uid, wardrobe) => {
    let items: { [key: string]: WardrobeItemType } = {}

    for (const category in wardrobe[uid]) {
      items = { ...items, ...wardrobe[uid][category as WardobeCategory] }
    }

    return items[itemId]
  })

export const selectWardrobeItemCount = createSelector(
  selectUid,
  selectDataWardrobe,
  (uid, wardrobe) => {
    let itemCount = 0

    if (!wardrobe) {
      return itemCount
    }

    for (const category in wardrobe[uid]) {
      itemCount += Object.values(wardrobe[uid][category as WardobeCategory]).length
    }

    return itemCount
  }
)

export const makeSelectWardrobeCategory = (...categories: WardobeCategory[]) =>
  createSelector(selectUid, selectDataWardrobe, (uid, wardrobe) => {
    let items: WardrobeMapType = {}

    if (!categories.length) {
      return null
    }

    for (const category of categories) {
      items = { ...items, [category]: wardrobe[uid][category] }
    }

    return items
  })
