import React from 'react'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

interface Props {
  current: number
  max: number
  tooltip: string
}

export const ItemsCount = ({ current, max, tooltip }: Props) => {
  const variant =
    current >= Math.floor(max / 1.2) ? 'danger' : current < Math.floor(max / 2) ? 'info' : 'primary'

  return (
    <OverlayTrigger
      placement={'right'}
      overlay={
        <Tooltip className="td-tooltip" id="items-count">
          {tooltip}
        </Tooltip>
      }
    >
      <Badge pill variant={variant} className="h6 ml-2">
        {current}/{max}
      </Badge>
    </OverlayTrigger>
  )
}
