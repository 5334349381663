import React from 'react'

export const HangerIcon = () => (
  <svg
    className="ui-icon hanger"
    width="32.386"
    height="26"
    viewBox="0 0 32.386 26"
  >
    <path
      d="M-44.961-104.221a5.492,5.492,0,0,0-4.029,1.673,5.492,5.492,0,0,0-1.673,4.029h3.269a2.338,2.338,0,0,1,.722-1.711,2.338,2.338,0,0,1,1.711-.722,2.338,2.338,0,0,1,1.711.722,2.338,2.338,0,0,1,.722,1.711,2.338,2.338,0,0,1-.722,1.711,2.338,2.338,0,0,1-1.711.722,1.487,1.487,0,0,0-1.14.494,1.587,1.587,0,0,0-.456,1.1V-91.6L-60.546-81.186a1.6,1.6,0,0,0-.532.836,1.769,1.769,0,0,0,0,1.026,1.512,1.512,0,0,0,.57.8,1.575,1.575,0,0,0,.95.3h29.193a1.575,1.575,0,0,0,.95-.3,1.512,1.512,0,0,0,.57-.8,1.769,1.769,0,0,0,0-1.026,1.6,1.6,0,0,0-.532-.836L-43.365-91.6v-1.52A5.635,5.635,0,0,0-40.4-95.136a5.4,5.4,0,0,0,1.14-3.383,5.492,5.492,0,0,0-1.673-4.029A5.492,5.492,0,0,0-44.961-104.221Zm0,15.433,9.731,7.3H-54.692Z"
      transform="translate(61.154 104.221)"
      fill="#a2999a"
    />
  </svg>
)
