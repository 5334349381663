import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'

import { useSelector } from './useSel'
import { selectUid } from '../store/auth/selectors'

export const useAnalytics = () => {
  const { pathname } = useLocation()
  const { analytics } = useFirebase()
  const uid = useSelector(selectUid)

  useEffect(() => {
    analytics().setCurrentScreen(pathname)
    analytics().logEvent('page_view', { screen_name: pathname })
    uid && analytics().setUserId(uid)
  }, [pathname, analytics, uid])

  return null
}
