import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link, useRouteMatch } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import classNames from 'classnames'

import UserIcon from '../../assets/svg/ui-icons/user.svg'

import { ROUTE_PATHS } from '../Routes'

export const Avatar = () => {
  const matchAccount = useRouteMatch(ROUTE_PATHS.ACCOUNT)
  const firebase = useFirebase()
  const user = firebase.auth().currentUser

  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        <Tooltip className="td-tooltip" id="items-count">
          Account
        </Tooltip>
      }
    >
      <Link to={ROUTE_PATHS.ACCOUNT}>
        <div
          className={classNames([
            'navigation-avatar-wrapper flex jc-center ai-center',
            { active: matchAccount?.path === ROUTE_PATHS.ACCOUNT },
          ])}
        >
          {user?.photoURL ? (
            <div
              className="navigation-avatar"
              style={{ backgroundImage: `url(${user?.photoURL})` }}
            />
          ) : (
            <img src={UserIcon} alt="Default user profile image" />
          )}
        </div>
      </Link>
    </OverlayTrigger>
  )
}
