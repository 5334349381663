import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useFirebase } from 'react-redux-firebase'
import Form from 'react-bootstrap/Form'
import Fade from 'react-bootstrap/Fade'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import firebase from 'firebase/compat/app'

import { Field, useValidateField } from '../../hooks/useValidateField'
import { useSelector } from '../../hooks/useSel'
import { selectAuthError } from '../../store/auth/selectors'
import { useFetchSignInMethod } from '../../hooks/useFetchSignInMethod'
import { useQuery } from '../../hooks/useQuery'
import { ROUTE_PATHS } from '../Routes'
import { DB_PATHS } from '../../constants'
import { generateReferalLink } from '../../helpers/firebaseDynamicLinks'
import { subscribeToMailerlite } from '../../api/mailerlite'

export const SignUp = () => {
  const query = useQuery()
  const history = useHistory()
  const { createUser, update, auth } = useFirebase()
  const referredBy = query.get('referredBy')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confrimPassword, setConfirmPassword] = useState('')
  const isPasswordMatch = confrimPassword === password
  const { isValid: isEmailValid, isInvalid: isEmailInvalid } = useValidateField(Field.Email, email)
  const { isValid: isPswValid, isInvalid: isPswInvalid } = useValidateField(
    Field.Password,
    password
  )
  const { fetchSignInMethod, signInMethodIsLoading, providerMessage } = useFetchSignInMethod(email)
  const error = useSelector(selectAuthError)

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const { uid, displayName } = await createUser(
        { email, password },
        { platform: 'web', showOnboarding: true, referredBy, email }
      )
      const splittedDisplayName = displayName?.split(' ') || ''
      const name = splittedDisplayName[0]
      const surname =
        splittedDisplayName.length > 1 ? splittedDisplayName[splittedDisplayName.length - 1] : ''
      const idToken = (await auth().currentUser?.getIdToken()) || ''
      const timestamp = moment().format()

      if (uid) {
        const { shortLink: referralLink } = await generateReferalLink(uid)
        await update(`${DB_PATHS.USERS}/${uid}`, { referralLink })
        firebase.analytics().setUserId(uid)
      }
      firebase.analytics().setUserProperties({
        platform: 'web',
      })
      await subscribeToMailerlite({
        email,
        idToken,
        name,
        signup_timestamp: timestamp,
        confirmation_timestamp: timestamp,
        fields: {
          last_name: surname,
          platform: 'web',
        },
      })
      history.replace(ROUTE_PATHS.WELCOME)
    } catch (error) {
      console.log('From handleOnSubmit:', error)
    }
  }

  const handleEmailOnBlur = () => fetchSignInMethod()

  return (
    <>
      <h2 className="txt-center">Create an account</h2>
      <p className="p txt-center mb-30">And learn to love your wardrobe again.</p>
      <Form onSubmit={handleOnSubmit}>
        <Form.Group controlId="formEmail" className="position-relative">
          <Form.Control
            type="email"
            placeholder="Email address"
            value={email}
            required
            onChange={(event) => setEmail(event.target.value)}
            onBlur={handleEmailOnBlur}
            isValid={!signInMethodIsLoading && isEmailValid && !error}
            isInvalid={isEmailInvalid}
          />

          {signInMethodIsLoading && (
            <Spinner variant={'info'} animation="border" size="sm" className="input-loader" />
          )}
        </Form.Group>

        <Form.Group controlId="formPassword" className="position-relative">
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            required
            onChange={(event) => setPassword(event.target.value)}
            isInvalid={isPswInvalid || Boolean(error)}
            isValid={isPswValid && !error}
          />
        </Form.Group>

        <Form.Group controlId="formConfirmPassword">
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            value={confrimPassword}
            required
            onChange={(event) => setConfirmPassword(event.target.value)}
            isValid={Boolean(confrimPassword && isPasswordMatch)}
            isInvalid={Boolean(confrimPassword && confrimPassword !== password)}
          />
        </Form.Group>

        {error && (
          <Fade in={Boolean(error)} appear>
            <p className="p p-xs txt-td-red">{error?.message}</p>
          </Fade>
        )}

        {providerMessage && (
          <Fade in={Boolean(providerMessage)} appear>
            <p className="p p-xs txt-td-red">{providerMessage}</p>
          </Fade>
        )}

        <Button
          variant="secondary"
          type="submit"
          block
          disabled={
            Boolean(providerMessage) ||
            signInMethodIsLoading ||
            !(isEmailValid && isPswValid) ||
            !isPasswordMatch
          }
        >
          SIGN UP
        </Button>
      </Form>
    </>
  )
}
