import React from 'react'
import { Button } from 'react-bootstrap'
import { useFirebase } from 'react-redux-firebase'
import { useAlert } from '../../hooks/useAlert'

export const LoginAndSecurity = () => {
  const firebase = useFirebase()
  const user = firebase.auth().currentUser
  const { setAlert } = useAlert()

  const handleOnLogout = () => firebase.logout()
  const handleOnResetPassword = () => {
    if (user?.email) {
      firebase.auth().sendPasswordResetEmail(user?.email, {
        url: 'https://web.thedrobe.com/account/login-and-security',
      })

      setAlert({
        text: 'We sent a reset link to your inbox! Check the junk folder too 📨',
        show: true,
        variant: 'success',
        duration: 6000,
      })
    }
  }

  return (
    <div>
      <div className="p-15 flex jc-space-between">
        <h3 className="h3">Login & Security</h3>
      </div>
      <div className="bg-offwhite horizontal-line" />

      <div className="flex flex-col jc-center ai-center">
        <div className="flex mt-30 jc-space-between ai-center col-7 border-bottom p-15 pt-0">
          <h5 className="mb-0">Forgot your password?</h5>
          <Button className="btn-td col-4" variant="secondary" onClick={handleOnResetPassword}>
            Reset password
          </Button>
        </div>
        <div className="flex mt-30 jc-space-between ai-center col-7 border-bottom p-15 pt-0">
          <h5 className="mb-0">Take a break 😴</h5>
          <Button className="btn-td col-4" variant="outline-warning" onClick={handleOnLogout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  )
}
