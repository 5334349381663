import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { PrivateRoute } from './PrivateRoute'
import { Auth } from '../Auth'
import { Palettes } from '../Palettes'
import { Wardrobe } from '../Wardrobe'
import { Outfits } from '../Outfits'
import { Account } from '../Account'
import { NotFound } from '../NotFound'
import { ROUTE_PATHS } from '.'
import { useAnalytics } from '../../hooks/useAnalytics'
import { Onboarding } from '../Onboarding'

export const MainRoutes = () => {
  useAnalytics()

  return (
    <Switch>
      <Route path={ROUTE_PATHS.LOGIN} exact>
        <Auth />
      </Route>
      <Route path={ROUTE_PATHS.SIGN_UP} exact>
        <Auth />
      </Route>
      <PrivateRoute path={ROUTE_PATHS.WELCOME} exact>
        <Onboarding />
      </PrivateRoute>
      <PrivateRoute path={ROUTE_PATHS.PALETTES}>
        <Palettes />
      </PrivateRoute>
      <PrivateRoute path={`${ROUTE_PATHS.WARDROBE}/:category?/:itemId?`}>
        <Wardrobe />
      </PrivateRoute>
      <PrivateRoute path={`${ROUTE_PATHS.OUTFITS}/:code?/:outfitId?/:variant?/:wardrobeItemId?`}>
        <Outfits />
      </PrivateRoute>
      <PrivateRoute path={`${ROUTE_PATHS.ACCOUNT}/:section?`}>
        <Account />
      </PrivateRoute>
      <PrivateRoute path={ROUTE_PATHS.BASE} exact>
        <>
          {/* <div>/</div> // Home or dashboard component */}
          <Redirect to={{ pathname: ROUTE_PATHS.PALETTES }} />
        </>
      </PrivateRoute>
      <PrivateRoute path={ROUTE_PATHS.ALL}>
        <NotFound />
      </PrivateRoute>
    </Switch>
  )
}
