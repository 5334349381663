import React from 'react'
import { Button, Fade } from 'react-bootstrap'
import { useFirebaseConnect } from 'react-redux-firebase'

import CopyIcon from '../../assets/svg/ui-icons/copy.svg'
import { DB_PATHS } from '../../constants'
import { useAlert } from '../../hooks/useAlert'
import { useSelector } from '../../hooks/useSel'
import { selectUid } from '../../store/auth/selectors'
import { selectUserProfile } from '../../store/profile/selectors'
import { selectReferralsInfo } from '../../store/referrals/selectors'
import { RewardsType } from '../../types/referrals'

export const referralRules = [
  { rewardType: 'wardrobe', rewardTypeEmoji: '👚', friendsRequired: 1 },
  { rewardType: 'outfits', rewardTypeEmoji: '👗', friendsRequired: 5 },
  { rewardType: 'palettes', rewardTypeEmoji: '🎨', friendsRequired: 10 },
]

export const InviteAndEarn = () => {
  const profile = useSelector(selectUserProfile)
  const uid = useSelector(selectUid)
  const referral = useSelector(selectReferralsInfo)
  const { setAlert } = useAlert()

  useFirebaseConnect([{ path: `${DB_PATHS.REFERRALS}/${uid}` }])

  const handleCopyInviteLink = async () => {
    if (profile?.referralLink) {
      await navigator.clipboard.writeText(profile?.referralLink)

      setAlert({
        text: 'Link copied to clipboard 📋',
        show: true,
        variant: 'success',
      })
    }
  }

  const renderEarnedReward = (reward: RewardsType) => {
    if (!referral) {
      return '-'
    }

    return referral[reward] ? referral[reward] : '0'
  }

  return (
    <div>
      <div className="p-15 flex jc-space-between ai-center">
        <h3 className="h3">Invite & Earn</h3>

        {profile?.referralLink && (
          <div className="col-6 flex">
            <Button
              className="badge-pill flex flex-one"
              variant="outline-secondary"
              title="Copy invite link"
              onClick={handleCopyInviteLink}
            >
              <span className="flex jc-space-between ai-center ml-15">
                {profile?.referralLink}
                <img
                  src={CopyIcon}
                  className="p-1 bg-white br-primary mr-15"
                  alt="Copy icon"
                  title="Copy invite link"
                />
              </span>
            </Button>
          </div>
        )}
        <Fade in={Boolean(referral)}>
          <h5>
            Signups so far:
            <span className="text-success font-weight-bold ml-1">
              {referral ? referral?.count : '0'}
            </span>
          </h5>
        </Fade>
      </div>
      <div className="bg-offwhite horizontal-line" />

      <div className="flex flex-col jc-center ai-center">
        <div className="col-7">
          <h3 className="txt-td-pink border-bottom p-15 pt-0 mt-30">{`Ready to grow your 'drobe?`}</h3>
          <p className="p p-15">{`Invite your friends to try the 'drobe and for every one that signs up, you'll earn yourself a reward!`}</p>

          <div className="flex flex-col p-30 bg-td-offwhite br-primary">
            {referralRules.map(({ rewardType, rewardTypeEmoji, friendsRequired }, i) => {
              return (
                <div className="border-bottom p-15 flex jc-space-between ai-center h5" key={i}>
                  <span className="txt-td-pink font-weight-bold"> 👯‍♀️ x{friendsRequired}</span>
                  <span>{rewardTypeEmoji}</span>
                  <span className="txt-td-black">
                    Earned: <strong>{renderEarnedReward(rewardType as RewardsType)}</strong>
                  </span>
                </div>
              )
            })}
          </div>

          <div className="flex jc-center ai-center mt-30">
            <Button
              className="col-5 text-uppercase"
              title="Copy invite link"
              onClick={handleCopyInviteLink}
            >
              Copy invite link & share
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
