import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { HuePicker, ColorResult } from 'react-color'
import { Saturation } from 'react-color/lib/components/common'
import tinycolor from 'tinycolor2'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { useQuery } from '../../hooks/useQuery'
import { useSelector } from '../../hooks/useSelector'
import { AppState } from '../../store'
import { PaletteType, ColourKeys } from '../../types'
import { selectUid } from '../../store/auth/selectors'
import { PointerCircle } from './PointerCircle'
import { useFirebase } from 'react-redux-firebase'
import { TIMESTAMP, DB_PATHS } from '../../constants'
import { DeleteBtn } from '../DeleteBtn'
import { ROUTE_PATHS } from '../Routes'
import { useAlert } from '../../hooks/useAlert'

interface PalettesMap {
  [key: string]: PaletteType
}

export const PaletteEditor = () => {
  const { update, remove } = useFirebase()
  const history = useHistory()
  const uid = useSelector(selectUid) as string
  const query = useQuery()
  const id = query.get('id')
  const blockId = query.get('blockId') as ColourKeys
  const palettes = useSelector(
    (state: AppState) => state.firebase.data.palettes[uid]
  ) as PalettesMap

  const [colour, setColour] = useState('')
  const [title, setTitle] = useState('')
  const [hsv, setHsv] = useState({ h: 0, s: 0, v: 0, a: 1 })
  const [showModal, setShowModal] = useState(false)
  const { setAlert } = useAlert()

  const saturationProps = {
    hsl: tinycolor(colour).toHsl(),
    hsv,
  }

  const formatColourType = () => {
    switch (blockId) {
      case 'a1':
      case 'a2':
      case 'a3':
      case 'a4':
        return 'Accent'
      case 'n1':
      case 'n2':
        return 'Neutral'
      case 'm1':
      case 'm2':
      case 'm3':
      default:
        return 'Main'
    }
  }

  useEffect(() => {
    if (palettes && id && blockId) {
      setColour(palettes[id].colours[blockId])
      setHsv(tinycolor(palettes[id].colours[blockId]).toHsv())
      setTitle(palettes[id].title)
    }
  }, [palettes, id, blockId])

  const handleDeleteBtn = () => setShowModal(true)
  const handleModalClose = () => setShowModal(false)
  const handleDeletePalette = () => {
    remove(`${DB_PATHS.PALETTES}/${uid}/${id}`)

    setAlert({ variant: 'danger', text: `${title} palette deleted`, show: true })
    history.push(ROUTE_PATHS.PALETTES)
    setShowModal(false)
  }

  const handleHexOnBlur = () => saveUpdatedColour(colour)
  const handleHexOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColour(e.target.value)
    setHsv(tinycolor(e.target.value).toHsv())
  }

  const handleTitleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    update(`${ROUTE_PATHS.PALETTES}/${uid}/${id}`, {
      title: e.target.value,
      updatedAt: TIMESTAMP,
    } as PaletteType)
  }

  const handleSaturationOnChange = (newColour: any) => {
    const newHexColour = tinycolor(newColour).toHexString()
    setColour(newHexColour)

    newColour.source === 'hsv' && setHsv(newColour)

    saveUpdatedColour(newHexColour)
  }

  const handleHueOnChange = (newColour: ColorResult) => saveUpdatedColour(newColour.hex)

  const saveUpdatedColour = (colour: string) => {
    update(`palettes/${uid}/${id}/colours`, {
      [blockId]: colour,
    })
    update(`${ROUTE_PATHS.PALETTES}/${uid}/${id}`, {
      updatedAt: TIMESTAMP,
    } as PaletteType)

    setAlert({ variant: 'success', text: 'Palette updated', show: true })
  }

  const renderModal = () => (
    <Modal
      size={'sm'}
      backdropClassName="modal-backdrop-td"
      show={showModal}
      onHide={handleModalClose}
      keyboard
    >
      <Modal.Header closeButton>
        <Modal.Title className="txt-td-pink">Delete palette</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete <strong>{title}</strong> palette? 😱
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-td" variant="outline-secondary" onClick={handleModalClose}>
          Keep it
        </Button>
        <Button className="btn-td" variant={'danger'} onClick={handleDeletePalette}>
          Delete it
        </Button>
      </Modal.Footer>
    </Modal>
  )

  if (id) {
    return (
      <>
        {renderModal()}
        <div className="bg-white editor">
          <div className="flex flex-row jc-space-between">
            <div className="flex-col">
              <h5 className="editor-label">Palette name</h5>
              <input
                type="text"
                className="mb-30 editor-title"
                value={title}
                onBlur={() =>
                  setAlert({ variant: 'success', text: `Palette name updated`, show: true })
                }
                onChange={handleTitleOnChange}
              />
            </div>
            <div className="flex-col">
              <DeleteBtn label={'Delete palette'} onClick={handleDeleteBtn} />
            </div>
          </div>
          <h3 className="editor-section-label">{formatColourType()} colour</h3>
          <h5 className="editor-label">Saturation</h5>
          <div className="editor-saturation mb-30">
            <Saturation
              color={colour}
              {...saturationProps}
              onChange={handleSaturationOnChange}
              pointer={PointerCircle}
            />
          </div>
          <h5 className="editor-label">Hue</h5>
          <HuePicker
            color={colour}
            onChange={(newColour) => setColour(newColour.hex)}
            height={'16px'}
            width={'100%'}
            onChangeComplete={handleHueOnChange}
          />
          <h5 className="editor-label mt-30">HEX</h5>
          <input
            type="text"
            className="input-colour"
            value={colour}
            onBlur={handleHexOnBlur}
            onChange={handleHexOnChange}
          />
        </div>
      </>
    )
  }

  return null
}
