import React from 'react'

export const WardrobeIcon = () => (
  <svg
    className="ui-icon wardrobe"
    width="26.03"
    height="26"
    viewBox="0 0 26.03 26"
  >
    <g transform="translate(-1464 -478)">
      <g transform="translate(1464 492.3)">
        <path
          d="M1464,612.2v11.7h26V612.2Zm15.664,7.011h-5.328a1.279,1.279,0,0,1,0-2.557h5.328a1.279,1.279,0,1,1,0,2.557Z"
          transform="translate(-1464 -612.2)"
          fill="#a2999a"
        />
      </g>
      <g transform="translate(1464.03 478)">
        <path
          d="M1464.284,478v11.7h26V478Zm15.664,7.012h-5.328a1.279,1.279,0,1,1,0-2.557h5.328a1.279,1.279,0,0,1,0,2.557Z"
          transform="translate(-1464.284 -478)"
          fill="#a2999a"
        />
      </g>
    </g>
  </svg>
)
