import axios from 'axios'
import { MailerliteSubscriber } from '../types/responses'
export interface MailerliteData {
  email: string | null
  idToken: string
  name?: string
  signup_ip?: string
  signup_timestamp?: string
  confirmation_ip?: string
  confirmation_timestamp?: string
  fields?: {
    platform?: 'web'
    membership?: string
    last_name?: string
  }
}

/**
 * Subscribe to Mailerlite `POST: /v1/mailerlite/subscribers`
 * @param data
 */
export const subscribeToMailerlite = (data: MailerliteData) => {
  if (process.env.NODE_ENV === 'development') {
    return
  }

  return axios.post<MailerliteSubscriber>(
    'https://api.thedrobe.com/v1/mailerlite/subscribers',
    {
      ...data,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${data.idToken}`,
      },
    }
  )
}
