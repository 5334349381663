import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

interface Props {
  show: boolean
  onModalClose: () => void
  onModalConfirm?: () => void
  size?: 'sm' | 'lg' | 'xl'
  title?: string
  body?: string | JSX.Element
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'
  ctaLabel?: string
  ctaDisabled?: boolean
  dismissLabel?: string
}

export const CustomModal = ({
  show,
  onModalClose,
  onModalConfirm,
  size,
  title,
  body,
  variant = 'primary',
  ctaLabel,
  ctaDisabled = false,
  dismissLabel = 'Cancel',
}: Props) => (
  <Modal
    size={size}
    backdropClassName="modal-backdrop-td"
    show={show}
    onHide={onModalClose}
    keyboard
  >
    {title && (
      <Modal.Header closeButton>
        <Modal.Title className="txt-td-pink">{title}</Modal.Title>
      </Modal.Header>
    )}
    {body && <Modal.Body>{body}</Modal.Body>}
    <Modal.Footer>
      <Button className="btn-td" variant="outline-secondary" onClick={onModalClose}>
        {dismissLabel}
      </Button>
      {ctaLabel && (
        <Button
          disabled={ctaDisabled}
          className="btn-td"
          variant={variant}
          onClick={onModalConfirm}
        >
          {ctaLabel}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
)
