import React, { useState, useEffect, useMemo } from 'react'
import { useFirebaseConnect, isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { ItemsCount } from '../ItemsCount'
import { MAX_OUTFITS } from '../../constants'
import { AddBtn } from '../AddBtn'
import { DB_PATHS } from '../../constants'
import { useSelector } from '../../hooks/useSel'
import { useSelector as useCustomSelector } from '../../hooks/useSelector'
import { selectCurrentUser, selectUid } from '../../store/auth/selectors'
import { selectOrderedOutfits, selectDataOutfitsCount } from '../../store/outfits/selectors'
import { Loader } from '../Loader'
import { EmptyState } from '../EmptyState'
import {
  OutfitsOrdered,
  OutfitCategories,
  OutfitsRouteParams,
  OrderedWardrobe,
  AccountRouteParams,
} from '../../types'
import { outfitCategoriesMap, defaultEmptyOutfit } from '../../data/outfits'
import { selectOrderedWardrobe } from '../../store/wardrobe/selectors'
import { Editor } from '../Editor'
import { ROUTE_PATHS } from '../Routes'
import { CustomModal } from '../CustomModal'
import { limitReached, LIMIT_REACHED, LimitReachedType } from '../../helpers'
import { useAlert } from '../../hooks/useAlert'
import { selectUIAlert } from '../../store/ui/selectors'
import { makeSelectIsSubActive } from '../../store/subscription/selectors'
import { Col, Nav, Row } from 'react-bootstrap'
import { Profile } from './Profile'
import { LoginAndSecurity } from './LoginAndSecurity'
import { InviteAndEarn } from './InviteAndEarn'

const accountTabs = [
  {
    key: 'profile',
    title: 'Profile',
    cmp: <Profile />,
  },
  {
    key: 'login-and-security',
    title: 'Login & Security',
    cmp: <LoginAndSecurity />,
  },
  {
    key: 'invite-and-earn',
    title: 'Invite & Earn',
    cmp: <InviteAndEarn />,
  },
]

export const Account = () => {
  const firebase = useFirebase()
  const history = useHistory()
  const { section } = useParams<AccountRouteParams>()
  const [activeKey, setActiveKey] = useState(section ?? 'profile')
  const uid = useSelector(selectUid)
  const user = useSelector(selectCurrentUser)
  const isSubActive = useSelector(makeSelectIsSubActive)
  const { setAlert } = useAlert()
  const alert = useSelector(selectUIAlert)
  const isValidSection = useMemo(() => section && accountTabs.some((tab) => tab.key === section), [
    section,
  ])

  // useFirebaseConnect([
  //   { path: `${DB_PATHS.OUTFITS}/${uid}` },
  //   { path: `${DB_PATHS.WARDROBE}/${uid}` },
  // ])

  useEffect(() => {
    if (!isValidSection) {
      history.replace(`${ROUTE_PATHS.ACCOUNT}/${activeKey}`)
    }
  }, [isValidSection, activeKey, history])

  const handleTabsOnSelect = (tabKey: string) => {
    setActiveKey(tabKey)
    history.replace(`${ROUTE_PATHS.ACCOUNT}/${tabKey}`)
  }

  if (!isLoaded(user)) {
    return <Loader fullScreen />
  }

  return (
    <>
      <div className="section-container bg-offwhite flex flex-row">
        <div className="section-items flex flex-col">
          <div className="section-container-heading pl-50 pt-30 pr-50 pb-15">
            <h1 className="flex ai-flex-start">Account</h1>
            <Alert className="m-0" variant={alert?.variant} show={alert?.show}>
              {alert?.text}
            </Alert>
          </div>

          <div className="tabs-container flex mt-15 mr-50 mb-50 ml-50">
            <Tab.Container
              id="account-tabs"
              defaultActiveKey="profile"
              activeKey={activeKey}
              onSelect={handleTabsOnSelect}
            >
              <div className="flex flex-row flex-one">
                <div className="col-3 border-right">
                  <Nav variant="pills" className="flex-column">
                    {accountTabs.map(({ key, title }) => (
                      <Nav.Item key={key}>
                        <Nav.Link eventKey={key} className="rounded-0">
                          {title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
                <div className="flex flex-one">
                  <Tab.Content className="flex flex-one">
                    {accountTabs.map(({ key, cmp }) => (
                      <Tab.Pane key={key} eventKey={key} className="flex flex-one">
                        {cmp && cmp}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  )
}
