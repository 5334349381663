import React from 'react'
import Button from 'react-bootstrap/Button'

interface Props {
  isWardrobe?: boolean
  isOutfits?: boolean
  isPalettes?: boolean
  onClick: () => void
}

export const EmptyState = ({ isPalettes = true, isWardrobe, isOutfits, onClick }: Props) => {
  const paletteText = 'No palettes created.<br/>Get started by adding one to your collection!'
  const wardrobeText = 'No wardrobe created.<br />Get started by creating new items!'
  const outfitText = 'No outfits created.<br />Get started by creating a new outfit!'

  const renderIcon = () => {
    if (isOutfits) {
      return '👗'
    }
    if (isWardrobe) {
      return '👚'
    }
    return '🎨'
  }

  const renderText = () => {
    if (isOutfits) {
      return outfitText
    }
    if (isWardrobe) {
      return wardrobeText
    }
    return paletteText
  }

  const renderButtonText = () => {
    if (isOutfits) {
      return 'Create a new outfit'
    }
    if (isWardrobe) {
      return 'Create a new item'
    }
    return 'Create a new palette'
  }

  return (
    <div className="empty-state-container flex flex-col jc-center ai-center">
      <div className="empty-state-inner flex flex-col jc-center ai-center p-50 bs-secondary">
        <div className="empty-state-emoji">{renderIcon()}</div>
        <div className="txt-center mt-15" dangerouslySetInnerHTML={{ __html: renderText() }} />
      </div>
      <div style={{ marginTop: '25px' }}>
        <Button className="btn-td" variant={'secondary'} onClick={onClick}>
          <div style={{}}>{renderButtonText()}</div>
        </Button>
      </div>
    </div>
  )
}
