import { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'

export const useFetchSignInMethod = (email: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const firebase = useFirebase()

  const fetchSignInMethod = async () => {
    if (!email) {
      return
    }

    setIsLoading(true)
    try {
      const providers = email && (await firebase.auth().fetchSignInMethodsForEmail(email))
      setIsLoading(false)

      if (providers && providers.length && providers[0] !== 'password') {
        setError(
          `You already have a linked account. Login with ${providers[0]} by using the button below.`
        )
      }
    } catch (error) {
      setIsLoading(false)
      console.error('From fetchSignInMethod:', error)
    }
  }

  return {
    fetchSignInMethod,
    signInMethodIsLoading: isLoading,
    providerMessage: error,
  }
}
