import React from 'react'
import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import Fade from 'react-bootstrap/Fade'

import { WardrobeVariants, OutfitCategories, OutfitsRouteParams } from '../../types'
import { useSelector } from '../../hooks/useSel'
import { makeSelectWardrobeItemById } from '../../store/wardrobe/selectors'
import { usePreloadImage } from '../../hooks/usePreloadImage'
import { useScrollIntoView } from '../../hooks/useScrollIntoView'
import { wardrobeVariantsEmojiMap } from '../../data/wardrobe'
import { ROUTE_PATHS } from '../Routes'

interface Props {
  outfitId: string
  variant: WardrobeVariants
  code: string
  wardrobeItemId?: string
}

export const OutfitBlock = React.memo(({ code, outfitId, variant, wardrobeItemId }: Props) => {
  const params = useParams<OutfitsRouteParams>()
  const { scrollRef, scrollRefIntoView } = useScrollIntoView<HTMLAnchorElement>()
  const wardrobeItem = useSelector(makeSelectWardrobeItemById(wardrobeItemId!))
  const { isImageLoading, hasImageFailed } = usePreloadImage(wardrobeItem?.image)
  const variantLowerCase = variant.toLowerCase()
  const pathItemId = wardrobeItemId ? wardrobeItemId : ''
  const toPath = `${ROUTE_PATHS.OUTFITS}/${code}/${outfitId}/${variantLowerCase}/${pathItemId}`

  if (hasImageFailed) {
    console.log('hasImageFailed', hasImageFailed)
  }

  const getBlockClassname = () => {
    switch (variant) {
      case 'TOP':
      case 'SHOES':
        return '--square'
      case 'OUTERWEAR':
      case 'PANTS_OR_DRESS':
        return '--portrait'
      default:
        return '--square-small'
    }
  }

  const blockClassNames = classNames([
    `outfit-card-block outfit-card-block${getBlockClassname()}`,
    { 'outfit-card-block-placeholder': !wardrobeItemId || !wardrobeItem },
    { 'wardrobe-image-loader': isImageLoading },
    {
      'outfit-card-block--selected':
        params.outfitId === outfitId && params.variant === variantLowerCase,
    },
  ])

  if (isImageLoading) {
    return (
      <div className={blockClassNames}>
        <h6 className="txt-td-pink m-0 txt-xs">Loading</h6>
      </div>
    )
  }

  return (
    <Fade in={!isImageLoading} appear={!isImageLoading}>
      <Link
        ref={scrollRef}
        to={toPath}
        onClick={scrollRefIntoView}
        className={blockClassNames}
        style={{
          backgroundImage: wardrobeItem ? `url(${wardrobeItem.image?.url})` : 'none',
        }}
      >
        {!wardrobeItem && (
          <span className="outfit-card-block-placeholder-icon h4 m-0">
            {wardrobeVariantsEmojiMap[variant]}
          </span>
        )}
      </Link>
    </Fade>
  )
})

OutfitBlock.displayName = 'OutfitBlock'
