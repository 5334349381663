import { useState, useEffect } from 'react'
import { AlertType } from '../types'
import { useDispatch } from 'react-redux'
import { updateUIAlert } from '../store/ui/actions'

export const useAlert = () => {
  const [alert, setAlert] = useState<AlertType>({
    show: false,
    text: '',
    variant: 'success',
  })
  const dispatch = useDispatch()

  useEffect(() => {
    let alertTimeOut: NodeJS.Timeout

    dispatch(updateUIAlert(alert))

    if (alert.show) {
      alertTimeOut = setTimeout(() => setAlert({ ...alert, show: false }), alert?.duration ?? 3000)
    }

    return () => {
      clearTimeout(alertTimeOut)
    }
  }, [alert, dispatch])

  return { alert, setAlert }
}
