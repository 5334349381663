import { PaletteType } from '../types'
import { TIMESTAMP } from '../constants'

const tdLightBlue = '#BCE0FD'

export const defaultEmptyPalette: PaletteType = {
  title: 'New palette',
  colours: {
    n1: tdLightBlue,
    n2: tdLightBlue,
    m1: tdLightBlue,
    m2: tdLightBlue,
    m3: tdLightBlue,
    a1: tdLightBlue,
    a2: tdLightBlue,
    a3: tdLightBlue,
    a4: tdLightBlue,
  },
  createdAt: TIMESTAMP,
  updatedAt: TIMESTAMP,
}
