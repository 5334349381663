import { useSelector as useSelectorRaw, shallowEqual } from 'react-redux'
import { AppState } from '../store'

// export function useSelector<TState = DefaultRootState, TSelected = unknown>(
//   selector: (state: TState) => TSelected,
//   equalityFn?: (left: TSelected, right: TSelected) => boolean
// ): TSelected;

// Wrapper around react-redux's `useSelector`, which uses shallow equals by
// default, since that's almost always what we want.

// export function useSelector<TState = AppState, TSelected = unknown>(
//   selector: (state: TState) => TSelected,
//   equalityFn = shallowEqual
// ) {
//   return useSelectorRaw(selector, equalityFn)
// }

export const useSelector = <TState extends AppState, TSelected extends unknown>(
  selector: (state: TState) => TSelected,
  equalityFn = shallowEqual
): TSelected => {
  return useSelectorRaw(selector, equalityFn)
}
