import axios from 'axios'

interface ServerResponse {
  colour: {
    rgb: string
    hex: string
  }
}

export const fetchColourFromImage = async (image: string) => {
  try {
    const data = await axios.post<ServerResponse>('https://api.thedrobe.com/v1/colour', { image })
    return data
  } catch (error) {
    console.log('error', error)
    throw new Error(error)
  }
}
