import axios from 'axios'

/**
 * Use `subscribeToMailerlite` instead
 * @deprecated
 */
export const addToMailchimp = (email: string) => {
  return axios.post('https://api.thedrobe.com/v1/mailchimp', {
    email_address: email,
    status: 'subscribed',
    merge_fields: {
      PLATFORM: 'web',
    },
  })
}
