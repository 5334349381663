import { createSelector } from 'reselect'

import { AppState } from '..'
import { selectUid } from '../auth/selectors'

export const selectOrderedOutfits = (state: AppState) => state.firebase.ordered.outfits
export const selectDataOutfits = (state: AppState) => state.firebase.data.outfits

export const selectDataOutfitsCount = createSelector(
  selectUid,
  selectDataOutfits,
  (uid, dataOutfits) => dataOutfits && dataOutfits[uid] && dataOutfits[uid].total
)
