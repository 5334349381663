import { createSelector } from 'reselect'

import { AppState } from '..'
import { selectUid } from '../auth/selectors'

export const selectReferralsInfo = createSelector(
  selectUid,
  (state: AppState) => state.firebase.data?.referrals,
  (uid, referrals) => referrals && referrals[uid]
)
