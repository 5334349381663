import { FirebaseDynamicLinks } from 'firebase-dynamic-links'

import { firebaseConfig } from '../config'

export const firebaseDynamicLinks = new FirebaseDynamicLinks(firebaseConfig.apiKey)

export const generateReferalLink = (uid: string) =>
  firebaseDynamicLinks.createLink({
    dynamicLinkInfo: {
      link: `https://web.thedrobe.com/sign-up?referredBy=${uid}`,
      domainUriPrefix: 'https://thedrobe.com/links',
      iosInfo: {
        iosBundleId: 'com.theDrobe',
        iosAppStoreId: '1448655936',
      },
      androidInfo: {
        androidPackageName: 'com.thedrobe',
      },
      navigationInfo: {
        enableForcedRedirect: true,
      },
      socialMetaTagInfo: {
        socialTitle: '👗Wear more 🛍 Shop less',
        socialDescription: 'Learn to love your wardrobe again 🖤',
        socialImageLink:
          'https://inspo.thedrobe.com/wp-content/uploads/2021/02/the-drobe-logomark-og.png',
      },
    },
  })
