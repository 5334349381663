import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { PaletteType } from '../../types'
import { BlockColour } from './BlockColour'
import { ROUTE_PATHS } from '../Routes'

interface Props {
  item: PaletteType
  id: string
  disabled?: boolean
}

export const Palette = ({ item, id, disabled = false }: Props) => {
  const { colours } = item
  type ColourList = typeof colours
  const history = useHistory()

  const renderColourBlocks = () => {
    const colourBlocks: JSX.Element[] = []
    const childrenA = []
    const childrenM = []
    const childrenN = []

    for (const key in colours) {
      const colour = colours[key as keyof ColourList]
      const blockId = key as keyof ColourList
      const template = (
        <BlockColour
          backgroundColor={colour}
          blockId={blockId}
          key={`${key}${colour}`}
          paletteId={id}
          disabled={disabled}
        />
      )

      if (key.includes('a')) {
        childrenA.push(template)
      }

      if (key.includes('m')) {
        childrenM.push(template)
      }

      if (key.includes('n')) {
        childrenN.push(template)
      }
    }

    colourBlocks.push(
      <div key="n" className="neautrals">
        {childrenN}
      </div>,
      <div key="m" className="mains">
        {childrenM}
      </div>,
      <div key="a" className="accents">
        {childrenA}
      </div>
    )

    return colourBlocks
  }

  const handleTitleOnClick = () => history.push(`${ROUTE_PATHS.PALETTES}?id=${id}&blockId=n1`)

  return (
    <div
      id={id}
      className={classNames(['palette-card m-15 mb-30', { 'palette-card--disabled': disabled }])}
    >
      <div className="palette-card-grid-container">{renderColourBlocks()}</div>
      <h5 className="palette-card-title" onClick={handleTitleOnClick}>
        {item.title}
      </h5>
    </div>
  )
}
