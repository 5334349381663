export const ROUTE_PATHS = {
  ACCOUNT: '/account',
  ALL: '*',
  BASE: '/',
  LOGIN: '/login',
  PALETTES: '/palettes',
  OUTFITS: '/outfits',
  SIGN_UP: '/sign-up',
  WARDROBE: '/wardrobe',
  WELCOME: '/welcome',
}
