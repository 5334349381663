import React from 'react'

import PlusIcon from '../assets/svg/ui-icons/plus.svg'
import { ItemTooltip } from './ItemTooltip'

interface Props {
  label?: string
  onClick: () => void
}

export const AddBtn = ({ onClick, label = 'Add' }: Props) => (
  <ItemTooltip tooltip={label}>
    <div className="round-btn bg-white" onClick={onClick}>
      <img src={PlusIcon} alt={label} title={label} />
    </div>
  </ItemTooltip>
)
