import { WardrobeVariants, WardobeCategory } from '../types'

export const convertVariantToCategory = (variant: WardrobeVariants): WardobeCategory => {
  switch (variant) {
    case 'HAT':
    case 'RING':
    case 'WATCH':
    case 'SUNGLASSES':
      return 'accessories'
    case 'BAG':
      return 'bags'
    case 'TOP':
      return 'tops'
    case 'OUTERWEAR':
      return 'outerwear'
    case 'SHOES':
      return 'shoes'
    case 'PANTS_OR_DRESS':
    default:
      return 'pants'
  }
}
