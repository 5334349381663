import React, { useCallback, useState, useEffect } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useDropzone } from 'react-dropzone'
import imageCompression from 'browser-image-compression'

import CloudUploadIcon from '../../assets/svg/ui-icons/cloud-upload.svg'
import Fade from 'react-bootstrap/Fade'
import { WardrobeImageType, WardobeCategory, AlertType } from '../../types'
import { usePreloadImage } from '../../hooks/usePreloadImage'
import { useSelector } from '../../hooks/useSel'
import { makeSelectWardrobeItemByIdAndCat } from '../../store/wardrobe/selectors'
import { DB_PATHS } from '../../constants'
import { fetchColourFromImage } from '../../api'

interface Props {
  category: WardobeCategory
  itemId: string
  uid: string
  setAlert: (alert: AlertType) => void
}

export const EditorImageUploader = ({ category, itemId, uid, setAlert }: Props) => {
  const wardrobeItem = useSelector(makeSelectWardrobeItemByIdAndCat(itemId, category)) || {}
  const [isImageUploading, setIsImageUploading] = useState(false)
  const [itemImage, setItemImage] = useState<WardrobeImageType>(wardrobeItem.image)
  const { isImageLoading, hasImageFailed } = usePreloadImage(itemImage)
  const firebase = useFirebase()
  useEffect(() => setItemImage(wardrobeItem.image), [wardrobeItem.image])

  const handleOnDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsImageUploading(true)
      try {
        // Grabs the added image and optimases it
        const rawImage = acceptedFiles[0]
        const compressedImage = await imageCompression(rawImage, {
          maxSizeMB: 1,
          maxWidthOrHeight: 768,
          useWebWorker: true,
        })

        const optimisedImage = new File([compressedImage], `${Date.now()}_${rawImage.name}`, {
          type: rawImage.type,
        })

        // Uploads data to be FB and gets HEX colour from uploaded image
        const { uploadTaskSnapshot } = await firebase.uploadFile(`${uid}/wardrobe`, optimisedImage)
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()
        const name = uploadTaskSnapshot.metadata.name
        const colourFromImage = await fetchColourFromImage(downloadURL)

        // Sync the active item to FB
        await firebase.update(`${DB_PATHS.WARDROBE}/${uid}/${category}/${itemId}`, {
          colour: colourFromImage.data.colour.hex,
          image: {
            url: downloadURL,
            name,
          },
        })

        setIsImageUploading(false)

        setAlert({
          text: 'Image updated',
          variant: 'success',
          show: true,
        })
      } catch (error) {
        setIsImageUploading(false)
        setAlert({
          text: error.message,
          variant: 'danger',
          show: true,
        })
      }
    },
    [uid, firebase, category, itemId, setAlert]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
    maxSize: 2097152, //2MB
    multiple: false,
    onDrop: handleOnDrop,
  })

  return (
    <div {...getRootProps({ className: 'editor-image-uploader br-primary mt-30 mb-30' })}>
      <input {...getInputProps()} />
      {isDragAccept && <h4 className="p-50">Drop your image here and the upload will start! 🚀</h4>}
      {isDragReject && <h4 className="p-50">🤔 Make sure your image is a PNG, JPG or GIF</h4>}
      {!isDragActive && !isImageUploading && !itemImage && (
        <>
          <img className="editor-image-upload-icon" src={CloudUploadIcon} alt="Upload icon" />
          <h4 className="txt-td-dgrey">Drag and drop an image here</h4>
          <h6 className="txt-td-dgrey">
            <em>or click here to browse your computer</em>
          </h6>
          <span className="txt-td-dgrey txt-xs bold mt-15">Max upload size 2MB</span>
        </>
      )}
      {isImageUploading && (
        <Fade in={isImageUploading}>
          <>
            <h2 className="editor-uploader-icon">☁️</h2>
            <h3 className="editor-uploader-text txt-td-pink">UPLOADING</h3>
          </>
        </Fade>
      )}
      {isImageLoading && <h3 className="editor-uploader-text txt-td-pink">LOADING IMAGE</h3>}
      {hasImageFailed && !isImageLoading && !isImageUploading && !isDragActive && (
        <>
          <h3 className="txt-td-red">This image was removed 🤷🏻‍♀️</h3>
          <h6 className="txt-td-dgrey">
            <em>Click here to upload an image for this item.</em>
          </h6>
          <span className="txt-td-dgrey txt-xs bold mt-15">Max upload size 2MB</span>
        </>
      )}
      {itemImage && !isImageLoading && !hasImageFailed && !isImageUploading && (
        <Fade in={Boolean(itemImage)}>
          <div
            className="editor-image-preview flex flex-col jc-flex-end ai-center"
            style={{
              backgroundImage: `url(${itemImage.url})`,
            }}
          >
            {isDragAccept && (
              <h6 className="bg-td-red txt-white bold p-1 br-primary bs-secondary">
                This image will be replaced
              </h6>
            )}
          </div>
        </Fade>
      )}
    </div>
  )
}
