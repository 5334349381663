import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Header } from './components/Header'
import { MobileAppMessage } from './components/MobileAppMessage'
import { MainRoutes } from './components/Routes/MainRoutes'

const App = () => {
  const [showMessagge, setShowMessagge] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowMessagge(true)
    } else {
      setShowMessagge(false)
    }
  }

  useEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  })

  if (showMessagge) {
    return <MobileAppMessage />
  }

  return (
    <Router>
      <Header />
      <MainRoutes />
    </Router>
  )
}

export default App

// const firebase = useFirebase()
// const image = useSelector((state: RootState) => state.firebase.auth.photoURL)
// const uid = useSelector((state: RootState) => state.firebase.auth.uid)
// const auth = useSelector((state: RootState) => state.firebase.data.outfits)
// const location = useLocation() || ''
// const isLogin = useRouteMatch('/login')

// useFirebaseConnect([
//   { path: `outfits/${uid}` },
//   { path: `palettes/${uid}` },
//   { path: `wardrobe/${uid}` },
// ])
// // const image = useSelector((state) => state.firebase.auth.photoURL)

// // console.log('todos', todos)

// // console.log('auth', auth)

// React.useEffect(() => {
//   firebase.login({
//     email: 'kans@hotmail.it',
//     password: 'test123',
//   })

//   return () => {}
// }, [])
