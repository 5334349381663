import React from 'react'
import classNames from 'classnames'

import { ImageIcon } from '../Icons/ImageIcon'
import { isLight } from '../../helpers'

interface Props {
  colour: string
  isLoading?: boolean
}

export const WardrobeItemPlaceholder = React.memo(({ colour, isLoading = false }: Props) => {
  const isColourLight = isLight(colour)
  const txtClass = isColourLight ? 'txt-td-black' : 'txt-td-offwhite'

  if (isLoading) {
    return <h6>Loading image</h6>
  }

  return (
    <>
      <ImageIcon isLight={isColourLight} />
      <h6 className={classNames([txtClass, 'mt-2'])}>No image</h6>
    </>
  )
})

WardrobeItemPlaceholder.displayName = 'WardrobeItemPlaceholder'
