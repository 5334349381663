import { useRef } from 'react'

export const useScrollIntoView = <T extends HTMLElement>() => {
  const scrollRef = useRef<T>(null)

  const scrollRefIntoView = () =>
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  return { scrollRef, scrollRefIntoView }
}
