import React, { useState, useEffect, useMemo } from 'react'
import { useFirebaseConnect, isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { ItemsCount } from '../ItemsCount'
import { MAX_OUTFITS } from '../../constants'
import { AddBtn } from '../AddBtn'
import { DB_PATHS } from '../../constants'
import { useSelector } from '../../hooks/useSel'
import { useSelector as useCustomSelector } from '../../hooks/useSelector'
import { selectUid } from '../../store/auth/selectors'
import { selectOrderedOutfits, selectDataOutfitsCount } from '../../store/outfits/selectors'
import { Loader } from '../Loader'
import { EmptyState } from '../EmptyState'
import { OutfitsOrdered, OutfitCategories, OutfitsRouteParams, OrderedWardrobe } from '../../types'
import { outfitCategoriesMap, defaultEmptyOutfit } from '../../data/outfits'
import { Outfit } from './Outfit'
import { selectOrderedWardrobe } from '../../store/wardrobe/selectors'
import { Editor } from '../Editor'
import { ROUTE_PATHS } from '../Routes'
import { CustomModal } from '../CustomModal'
import { limitReached, LIMIT_REACHED, LimitReachedType } from '../../helpers'
import { useAlert } from '../../hooks/useAlert'
import { selectUIAlert } from '../../store/ui/selectors'
import { makeSelectIsSubActive } from '../../store/subscription/selectors'
import { selectReferralsInfo } from '../../store/referrals/selectors'

export const Outfits = () => {
  const firebase = useFirebase()
  const history = useHistory()
  const { code, outfitId, variant } = useParams<OutfitsRouteParams>()
  const uid = useSelector(selectUid)
  const isSubActive = useSelector(makeSelectIsSubActive)
  const outfitsCount = useSelector(selectDataOutfitsCount)
  const outfits = useCustomSelector(selectOrderedOutfits) as OutfitsOrdered
  const wardrobe = useCustomSelector(selectOrderedWardrobe) as OrderedWardrobe
  const [activeKey, setActiveKey] = useState(code as OutfitCategories)
  const [showModal, setShowModal] = useState(false)
  const [isNewOutfit, setIsNewOutfit] = useState(false)
  const [newOutfitCode, setNewOutfitCode] = useState<OutfitCategories | ''>('')
  const referral = useSelector(selectReferralsInfo)
  const maxOutfits = referral && referral?.outfits ? MAX_OUTFITS + referral.outfits : MAX_OUTFITS
  const modalContent = limitReached(LIMIT_REACHED.OUTFITS as LimitReachedType, maxOutfits)
  const { setAlert } = useAlert()
  const alert = useSelector(selectUIAlert)

  const isValidPathCode = useMemo(
    () => outfits && outfits[uid] && !outfits[uid].some(({ key }) => code && code === key),
    [outfits, uid, code]
  )

  useFirebaseConnect([
    { path: `${DB_PATHS.OUTFITS}/${uid}` },
    { path: `${DB_PATHS.WARDROBE}/${uid}` },
    { path: `${DB_PATHS.REFERRALS}/${uid}` },
  ])

  useEffect(() => {
    if (outfits && outfits[uid]?.length) {
      if (!code || !outfitCategoriesMap[code as OutfitCategories] || isValidPathCode) {
        setActiveKey(outfits[uid][0].key)
        history.replace(`${ROUTE_PATHS.OUTFITS}/${outfits[uid][0].key}`)
      }
    }
  }, [outfits, code, uid, history, isValidPathCode])

  const handleAddBtn = () => {
    if (!isSubActive && outfitsCount >= maxOutfits) {
      return setShowModal(true)
    }

    setShowModal(true)
    setIsNewOutfit(true)
  }

  const handleAddOutfit = async () => {
    if (newOutfitCode) {
      const payload = { ...defaultEmptyOutfit }

      try {
        const savedOutfitSnapshot = await firebase.push(
          `${DB_PATHS.OUTFITS}/${uid}/${newOutfitCode}`,
          payload
        )
        await firebase
          .ref(`${DB_PATHS.OUTFITS}/${uid}/total`)
          .transaction((currentTotal) => currentTotal + 1)

        if (savedOutfitSnapshot.key) {
          history.replace(`${ROUTE_PATHS.OUTFITS}/${newOutfitCode}/${savedOutfitSnapshot.key}/top`)
          setActiveKey(newOutfitCode)
          setAlert({ text: 'Outfit created', variant: 'success', show: true })

          setShowModal(false)
          setNewOutfitCode('')
          setIsNewOutfit(false)
        }
      } catch (error) {
        setAlert({ text: error.message, variant: 'danger', show: true })
      }
    }
  }

  const handleModalClose = () => {
    setShowModal(false)
    setIsNewOutfit(false)
    setNewOutfitCode('')
  }

  const handleTabsOnSelect = (tabKey: string) => {
    setActiveKey(tabKey as OutfitCategories)
    history.replace(`${ROUTE_PATHS.OUTFITS}/${tabKey}`)
  }

  const renderNewOutfitModalContent = () => (
    <Form.Group className="form-control-select">
      <Form.Control
        as="select"
        onChange={(e) => setNewOutfitCode(e.target.value as OutfitCategories)}
        defaultValue={''}
      >
        <option disabled value={''}>
          Choose a category
        </option>
        {Object.values(outfitCategoriesMap).map(({ label, code }, i) => (
          <option key={i} value={code}>
            {label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )

  const renderCustomModal = () => (
    <CustomModal
      show={showModal}
      onModalClose={handleModalClose}
      onModalConfirm={isNewOutfit ? handleAddOutfit : () => setShowModal(false)}
      body={isNewOutfit ? renderNewOutfitModalContent() : modalContent.body}
      title={isNewOutfit ? 'Create a new outfit' : modalContent.title}
      ctaDisabled={isNewOutfit && !newOutfitCode}
      ctaLabel={isNewOutfit ? 'Create' : 'Got it'}
    />
  )

  if (!isLoaded(outfits && wardrobe)) {
    return <Loader fullScreen />
  }

  if (isEmpty(outfits[uid])) {
    return (
      <>
        {renderCustomModal()}
        <EmptyState isOutfits onClick={handleAddBtn} />
      </>
    )
  }

  return (
    <>
      {renderCustomModal()}
      <div className="section-container bg-offwhite flex flex-row">
        <div className="section-items flex flex-col">
          <div className="section-container-heading pl-50 pt-30 pr-50 pb-15">
            <h1 className="flex ai-flex-start">
              Outfits
              {!isSubActive && (
                <ItemsCount
                  tooltip={'Outfits used with free plan'}
                  current={outfitsCount}
                  max={maxOutfits}
                />
              )}
            </h1>
            <Alert className="m-0" variant={alert?.variant} show={alert?.show}>
              {alert?.text}
            </Alert>
            <AddBtn onClick={handleAddBtn} label={'Create outfit'} />
          </div>

          <div className="tabs-container mt-15 mr-50 mb-50 ml-50">
            <Tabs
              defaultActiveKey={activeKey || outfits[uid][0].key}
              id="outfits-tab"
              className="tabs-navigator"
              onSelect={handleTabsOnSelect}
              activeKey={activeKey}
            >
              {outfits &&
                outfits[uid].map(({ key, value }) => {
                  if ((key as 'total' | OutfitCategories) === 'total') return null

                  return (
                    <Tab
                      key={key}
                      eventKey={key}
                      title={`${outfitCategoriesMap[key].label} ${outfitCategoriesMap[key].icon}`}
                    >
                      <div className="tab-content-inner">
                        {Object.keys(value).map((el, i) => {
                          return (
                            <Outfit code={activeKey} key={i} outfit={value[el]} outfitId={el} />
                          )
                        })}
                      </div>
                    </Tab>
                  )
                })}
            </Tabs>
          </div>
        </div>

        {code && outfitId && variant && <Editor />}
      </div>
    </>
  )
}
