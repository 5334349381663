import React from 'react'

import TDicon from '../assets/svg/the-drobe-logomark.svg'

interface Props {
  fullScreen?: boolean
}

export const Loader = ({ fullScreen = false }: Props) => {
  if (fullScreen) {
    return (
      <div className="empty-state-container bg-offwhite flex flex-col jc-center ai-center">
        <img src={TDicon} className="mb-15 loader" />
        <h2 className="loader-text">Loading</h2>
      </div>
    )
  }

  return <img src={TDicon} className="loader" />
}
