import { OutfitCategoriesMap, OutfitType } from '../types'
import { TIMESTAMP } from '../constants'

export const outfitCategoriesMap: OutfitCategoriesMap = {
  'SCL-D': { label: 'Social (daytime)', code: 'SCL-D', icon: `☕️` },
  'SCL-N': { label: 'Social (nighttime)', code: 'SCL-N', icon: `🍸` },
  'WRK-O': { label: 'Work (office)', code: 'WRK-O', icon: '🖥' },
  'WRK-H': { label: 'Work (from home)', code: 'WRK-H', icon: '👩‍💻' },
  CHL: { label: 'Chilling at home', code: 'CHL', icon: '🛋' },
  'XRC-I': { label: 'Exercise (indoors)', code: 'XRC-I', icon: '🧘‍♀️' },
  'XRC-O': { label: 'Exercise (outdoors)', code: 'XRC-O', icon: '🏃‍♀️' },
  'BLK-T': { label: 'Black tie', code: 'BLK-T', icon: '🥂' },
}

export const defaultEmptyOutfit: OutfitType = {
  createdAt: TIMESTAMP as number, // timestamp is converted into a number SS
  updatedAt: TIMESTAMP as number,
  items: { TOP: '' }, // add one empty element for good measure
}
