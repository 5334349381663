import React, { useState } from 'react'
import Fade from 'react-bootstrap/Fade'

type Props = {
  children: JSX.Element
  tooltip: string
  tag?: keyof React.ReactHTML
}

export const ItemTooltip = ({ children, tooltip, tag = 'div' }: Props) => {
  const Tag = tag
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <Tag
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className="item-tooltip-wrapper"
    >
      {children}
      <Fade in={showTooltip} appear unmountOnExit>
        <span className="item-tooltip">{tooltip}</span>
      </Fade>
    </Tag>
  )
}
