import React from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import VideoPlayer from 'react-player'
import { Link, useHistory } from 'react-router-dom'

import TDlogo from '../../assets/svg/the-drobe-logo-horizontal-variant.svg'
import { ROUTE_PATHS } from '../Routes'
import { selectUid } from '../../store/auth/selectors'
import { DB_PATHS } from '../../constants'

export const Onboarding = () => {
  const uid = useSelector(selectUid)
  const firease = useFirebase()
  const history = useHistory()

  const handleOnClick = async () => {
    await firease.update(`${DB_PATHS.USERS}/${uid}`, { showOnboarding: false })
  }

  const handleOnEnded = () => {
    handleOnClick()
    history.replace(ROUTE_PATHS.PALETTES)
  }

  return (
    <div className="container position-relative">
      <div className="flex row jc-center mt-50 mb-50">
        <img src={TDlogo} alt="The 'drobe logo - Horizontal variant" />
      </div>
      <div className="flex flex-col jc-center ai-center mt-50">
        <h1>Hey, friend 👋🏼</h1>
        <p>{`Here's a quick virtual tour of The 'drobe. Happy travels!`}</p>
        <VideoPlayer
          className="bg-td-black br-primary bs-primary overflow-hidden mt-15"
          url={'https://vimeo.com/440622393'}
          loop={false}
          controls
          onEnded={handleOnEnded}
        />

        <Link to={ROUTE_PATHS.PALETTES} className="mt-30 txt-td-dgrey" onClick={handleOnClick}>
          Skip
        </Link>
      </div>
    </div>
  )
}
