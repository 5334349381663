import React from 'react'
import tinycolor from 'tinycolor2'
import classNames from 'classnames'
import { SaturationProps } from 'react-color/lib/components/common/Saturation'

export const PointerCircle = ({ color }: SaturationProps) => {
  const isLight = tinycolor(color).isLight() && 'editor-pointer--light'

  return <div className={classNames(['editor-pointer', isLight])} />
}
