import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { Props as FirebaseuiProps } from 'react-firebaseui'
import firebase from 'firebase/compat/app'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { DB_PATHS } from '../../constants'
import { ROUTE_PATHS } from '../Routes'
import { useQuery } from '../../hooks/useQuery'
import { generateReferalLink } from '../../helpers/firebaseDynamicLinks'
import { subscribeToMailerlite } from '../../api/mailerlite'

export const SocialAuth = () => {
  const { update, auth } = useFirebase()
  const history = useHistory()
  const query = useQuery()
  const referredBy = query.get('referredBy')
  const uiConfig: FirebaseuiProps['uiConfig'] = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      'apple.com',
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential) => {
        if (authResult.additionalUserInfo?.isNewUser) {
          if (authResult.user?.uid && authResult.user?.email) {
            handleUserUpdate(authResult.user?.uid, authResult.user?.email, {
              email: authResult.user?.email,
              showOnboarding: true,
              platform: 'web',
              referredBy,
            })

            history.replace(ROUTE_PATHS.WELCOME)
          }
        }

        return false
      },
    },
  }

  const handleUserUpdate = async (uid: string, email: string, value: object) => {
    const { shortLink: referralLink } = await generateReferalLink(uid)
    await update(`${DB_PATHS.USERS}/${uid}`, { ...value, referralLink })
    const idToken = (await auth().currentUser?.getIdToken()) || ''
    const timestamp = moment().format()
    firebase.analytics().setUserId(uid)
    firebase.analytics().setUserProperties({
      platform: 'web',
    })

    await subscribeToMailerlite({
      email,
      idToken,
      signup_timestamp: timestamp,
      confirmation_timestamp: timestamp,
      fields: {
        platform: 'web',
      },
    })
  }

  return (
    <>
      <div className="flex jc-center ai-center mt-15">
        <div className="bg-offwhite horizontal-line" />
        <p className="mb-0 ml-15 mr-15 txt-td-dgrey">OR</p>
        <div className="bg-offwhite horizontal-line" />
      </div>

      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </>
  )
}
