import React from 'react'

import { COLOURS } from '../../constants'

export const ImageIcon = ({ isLight }: { isLight: boolean }) => {
  const fill = isLight ? COLOURS.TD_BLK : COLOURS.TD_OFFWHITE
  return (
    <svg className="ui-icon hanger" width="30.3" height="26" viewBox="0 0 30.3 26">
      <path
        fill={fill}
        d="M26,0H4.3C1.9,0,0,1.9,0,4.3v17.3C0,24.1,1.9,26,4.3,26H26c2.4,0,4.3-1.9,4.3-4.3V4.3 C30.3,1.9,28.4,0,26,0z M20.6,4.3c1.8,0,3.2,1.5,3.2,3.2s-1.5,3.2-3.2,3.2s-3.2-1.5-3.2-3.2C17.3,5.8,18.8,4.3,20.6,4.3z M4.3,23.8 c-1.2,0-2.2-1-2.2-2.2v-4.6l6.4-5.7c1.3-1.1,3.2-1.1,4.5,0.1l4.4,4.4l-7.9,7.9H4.3z M28.2,21.7c0,1.2-1,2.2-2.2,2.2H12.6l8.2-8.2 c1.2-1,3-1,4.2,0l3.2,2.7V21.7z"
      />
    </svg>
  )
}
