import { AlertType } from '../../types'

export const UPDATE_ALERT = 'UI/UPDATE_ALERT'

interface UpdateAlertAction {
  type: typeof UPDATE_ALERT
  payload: AlertType
}

export type UIActionTypes = UpdateAlertAction
