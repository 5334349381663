import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty, useFirebase } from 'react-redux-firebase'

import {
  OutfitsRouteParams,
  WardrobeVariants,
  WardobeCategory,
  OutfitType,
  OutfitCategories,
} from '../../types'
import { convertVariantToCategory } from '../../helpers'
import { wardrobeEmojiMap } from '../../data/wardrobe'
import { useSelector } from '../../hooks/useSel'
import { makeSelectWardrobeCategory } from '../../store/wardrobe/selectors'
import { WardrobeImageItem, WardrobeImageItemSelected } from '../Wardrobe/WardrobeImageItem'
import { DB_PATHS, TIMESTAMP } from '../../constants'
import { selectUid } from '../../store/auth/selectors'
import { ROUTE_PATHS } from '../Routes'
import { DeleteBtn } from '../DeleteBtn'
import { CustomModal } from '../CustomModal'
import { useAlert } from '../../hooks/useAlert'
import { outfitCategoriesMap } from '../../data/outfits'

export const OutfitEditor = () => {
  const uid = useSelector(selectUid)
  const firebase = useFirebase()
  const history = useHistory()
  const { setAlert } = useAlert()
  const { variant, wardrobeItemId, outfitId, code } = useParams<OutfitsRouteParams>()
  const [showModal, setShowModal] = useState(false)
  const capsVariant = variant?.toUpperCase() as WardrobeVariants
  const convertedVariant = convertVariantToCategory(capsVariant)
  const pantsOrDress = ['pants', 'dresses', 'skirts'] as WardobeCategory[]
  const wardrobeCategories = convertedVariant === 'pants' ? pantsOrDress : [convertedVariant]
  const selectedWardrobeCategory = useSelector(makeSelectWardrobeCategory(...wardrobeCategories))
  const outfitIdDbPath = `${DB_PATHS.OUTFITS}/${uid}/${code}/${outfitId}`

  const handleItemOnClick = ({ selectedItemId, clearItem }: WardrobeImageItemSelected) => {
    if (!outfitId || !code || !variant || !outfitCategoriesMap[code as OutfitCategories]) {
      return null
    }

    firebase.update(`${outfitIdDbPath}/items`, {
      [capsVariant]: clearItem ? null : selectedItemId,
    })
    firebase.update(`${outfitIdDbPath}`, { updatedAt: TIMESTAMP } as OutfitType)

    setAlert({ variant: 'success', text: 'Outfit updated', show: true })

    return history.push(
      `${ROUTE_PATHS.OUTFITS}/${code}/${outfitId}/${variant}/${clearItem ? '' : selectedItemId}`
    )
  }

  const handleDeleteBtn = () => setShowModal(true)

  const handleDeleteOutfit = async () => {
    setAlert({ variant: 'danger', text: 'Outfit deleted', show: true })
    setShowModal(false)
    firebase.remove(outfitIdDbPath)
    await firebase
      .ref(`${DB_PATHS.OUTFITS}/${uid}/total`)
      .transaction((currentTotal) => currentTotal - 1)
    history.push(`${ROUTE_PATHS.OUTFITS}/${code}`)
  }

  const renderWardrobeItemsPicker = () => {
    const content = []

    for (const key in selectedWardrobeCategory) {
      const typedKey = key as WardobeCategory
      const wardrobeItems = selectedWardrobeCategory[typedKey]

      for (const itemId in wardrobeItems) {
        if (!wardrobeItems[itemId].image) {
          delete wardrobeItems[itemId]
        }
      }

      content.push({ section: key, items: wardrobeItems })
    }

    return content.map(({ section, items }, i) => {
      if (isEmpty(items)) {
        return null
      }

      return (
        <div key={i} className="bg-white br-primary bs-secondary mb-30">
          <h5 className="editor-label txt-capitalize p-15 mb-0 border-bottom flex jc-space-between">
            {`${section} ${wardrobeEmojiMap[section as WardobeCategory]}`}
          </h5>
          <div className={'flex flex-wrap jc-space-between p-2'}>
            {items &&
              Object.entries(items).map(([itemId, item], idx) => {
                return (
                  <WardrobeImageItem
                    onClick={handleItemOnClick}
                    selected={itemId === wardrobeItemId}
                    margin={'sm'}
                    key={idx}
                    itemId={itemId}
                    item={item}
                  />
                )
              })}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="bg-white editor">
        <div className="flex-col flex-one">
          <div className="flex flex-row jc-flex-end sticky-top mb-30">
            <DeleteBtn label={'Delete outfit'} onClick={handleDeleteBtn} />
          </div>
          {renderWardrobeItemsPicker()}
        </div>
      </div>

      <CustomModal
        show={showModal}
        onModalClose={() => setShowModal(false)}
        onModalConfirm={handleDeleteOutfit}
        size={'sm'}
        body={
          <p>
            Are you sure you want to delete this outfit?
            <br />
            <strong>{`This action can't be undone`}</strong> 😱
          </p>
        }
        title={'Delete outfit'}
        ctaLabel={'Delete it'}
        dismissLabel={'Keep it'}
        variant={'danger'}
      />
    </>
  )
}
