// Keep in sync with the same file in The drobe app
import { COLOURS } from '../constants'
import { WardrobeVariants } from '../types'

export const wardrobeList = {
  category: [
    { label: 'Tops' },
    { label: 'Skirts' },
    { label: 'Dresses' },
    { label: 'Pants' },
    { label: 'Outerwear' },
    { label: 'Shoes' },
    { label: 'Bags' },
    { label: 'Accessories' },
  ],
  fabric: [
    { label: 'Angora' },
    { label: 'Cashmere' },
    { label: 'Chambray' },
    { label: 'Chiffon' },
    { label: 'Corduroy' },
    { label: 'Cotton' },
    { label: 'Denim' },
    { label: 'Faux fur' },
    { label: 'Faux leather' },
    { label: 'Fleece' },
    { label: 'Lace' },
    { label: 'Leather' },
    { label: 'Linen' },
    { label: 'Mohair' },
    { label: 'Polyester' },
    { label: 'Raw denim' },
    { label: 'Rayon' },
    { label: 'Satin' },
    { label: 'Silk' },
    { label: 'Spandex' },
    { label: 'Suede' },
    { label: 'Tweed' },
    { label: 'Velvet' },
    { label: 'Viscose' },
    { label: 'Wool' },
  ],
  fabricForShoesAndBags: [
    { label: 'Leather' },
    { label: 'Hair' },
    { label: 'Patent' },
    { label: 'Suede' },
    { label: 'Canvas' },
    { label: 'Rubber' },
    { label: 'Satin' },
    { label: 'Other' },
  ],
  tops: {
    necklines: [
      { label: 'Boat neck' },
      { label: 'Cowl neck' },
      { label: 'Crew neck' },
      { label: 'Halter neck' },
      { label: 'Scoop neck' },
      { label: 'Square neck' },
      { label: 'Sweetheart' },
      { label: 'Turtleneck' },
      { label: 'V-neck' },
    ],
    sleeves: [
      { label: '3/4 length' },
      { label: 'Cap' },
      { label: 'Short' },
      { label: 'Elbow length' },
      { label: 'Off the shoulder' },
      { label: 'Long' },
      { label: 'Strapless' },
      { label: 'Spaghetti ' },
      { label: 'Sleeveless' },
    ],
    fit: [
      { label: 'Bodycon' },
      { label: 'Slim' },
      { label: 'Relaxed' },
      { label: 'Oversized' },
      { label: 'Empire' },
      { label: 'Tailored' },
    ],
  },
  pants: {
    type: [
      { label: 'Bootcut' },
      { label: 'Skinny' },
      { label: 'Slim' },
      { label: 'Straight' },
      { label: 'Flared' },
      { label: 'Tapered' },
      { label: 'Harem' },
      { label: 'Wide' },
    ],
    rise: [{ label: 'Low' }, { label: 'Mid' }, { label: 'High' }],
    length: [
      { label: 'Full' },
      { label: 'Ankle' },
      { label: '3/4' },
      { label: 'Knee' },
      { label: 'Above knee' },
      { label: 'Short' },
      { label: 'Mini' },
    ],
  },
  skirts: {
    type: [
      { label: 'Full' },
      { label: 'A-line' },
      { label: 'Straight' },
      { label: 'Pencil' },
      { label: 'Pleated' },
      { label: 'Bodycon' },
    ],
    rise: [{ label: 'Low' }, { label: 'Mid' }, { label: 'High' }],
    length: [
      { label: 'Long' },
      { label: 'Midi' },
      { label: 'Knee' },
      { label: 'Above knee' },
      { label: 'Short' },
      { label: 'Mini' },
    ],
  },
  dresses: {
    necklines: [
      { label: 'Boat neck' },
      { label: 'Cowl neck' },
      { label: 'Crew neck' },
      { label: 'Halter neck' },
      { label: 'Scoop neck' },
      { label: 'Square neck' },
      { label: 'Sweetheart' },
      { label: 'Turtleneck' },
      { label: 'V-neck' },
    ],
    sleeves: [
      { label: '3/4 length' },
      { label: 'Cap' },
      { label: 'Elbow length' },
      { label: 'Off the shoulder' },
      { label: 'Long' },
      { label: 'Strapless' },
      { label: 'Spaghetti ' },
      { label: 'Sleeveless' },
    ],
    length: [
      { label: 'Long' },
      { label: 'Midi' },
      { label: 'Knee' },
      { label: 'Above knee' },
      { label: 'Short' },
      { label: 'Mini' },
    ],
  },
  outerwear: {
    type: [
      { label: 'Cardigan' },
      { label: 'Blazer' },
      { label: 'Trench' },
      { label: 'Bomber' },
      { label: 'Puffer' },
      { label: 'Biker' },
      { label: 'Denim' },
      { label: 'Vest' },
      { label: 'Fleece' },
      { label: 'Bolero' },
    ],
    length: [{ label: 'Cropped' }, { label: 'Standard' }, { label: 'Long' }],
    fit: [{ label: 'Oversized' }, { label: 'Relaxed' }, { label: 'Slim' }, { label: 'Tailored' }],
  },
  shoes: {
    type: [
      { label: 'Ballet' },
      { label: 'Boots' },
      { label: 'Espadrilles' },
      { label: 'Flip flops' },
      { label: 'Loafers' },
      { label: 'Mules' },
      { label: 'Oxfords/Brogues' },
      { label: 'Pumps' },
      { label: 'Sandals' },
      { label: 'Slides' },
      { label: 'Sneakers' },
    ],
    length: [
      { label: 'Ankle' },
      { label: 'Calf' },
      { label: 'Knee' },
      { label: 'Thigh' },
      { label: 'N/A' },
    ],
    heel: [
      { label: 'Block' },
      { label: 'Flat' },
      { label: 'Kitten' },
      { label: 'Stiletto' },
      { label: 'Wedge' },
    ],
  },
  bags: {
    type: [
      { label: 'Backpack' },
      { label: 'Bucket' },
      { label: 'Crossbody/Sling' },
      { label: 'Clutch' },
      { label: 'Hobo' },
      { label: 'Saddle' },
      { label: 'Satchel' },
      { label: 'Tote/Shopper' },
      { label: 'Wristlet' },
    ],
  },
  accessories: {
    type: [
      { label: 'Belt' },
      { label: 'Hat' },
      { label: 'Jewelry' },
      { label: 'Scarf' },
      { label: 'Sunglasses' },
      { label: 'Watch' },
    ],
  },
}

// This is the default for the mobile app and it should change to a simplified version
//
// export const defaultEmptyItem = {
//   id: '',
//   category: '',
//   tops: { necklines: '', sleeves: '', fit: '' },
//   pants: { type: '', rise: '', length: '' },
//   skirts: { type: '', rise: '', length: '' },
//   dresses: { necklines: '', sleeves: '', length: '' },
//   outerwear: { type: '', length: '', fit: '' },
//   bags: { type: '' },
//   shoes: { type: '', heel: '', length: '' },
//   accessories: { type: '' },
//   fabric: '',
//   colour: '#FF00FF',
//   defaultHexValue: '#BCE0FD',
//   image: null,
// }
export const defaultEmptyItem = {
  category: '',
  colour: COLOURS.TD_LIGHT_BLUE,
  image: null,
}

export const wardrobeEmojiList = [
  '👚',
  '👖',
  '👓',
  '🧥',
  '👜',
  '💍',
  '🥿',
  '👠',
  '👢',
  '🕶',
  '👡',
  '👟',
  '👝',
  '👛',
  '🎒',
  '👗',
  '👘',
  '👒',
  '🧢',
  '🧣',
  '⌚️',
]

export const wardrobeEmojiMap = {
  tops: '👚',
  skirts: '🗻',
  dresses: '👗',
  pants: '👖',
  outerwear: '🧥',
  shoes: '👢',
  bags: '👜',
  accessories: '⌚️',
}

export const wardrobeVariantsEmojiMap: { [key in WardrobeVariants]: string } = {
  RING: '💍',
  SUNGLASSES: '🕶',
  HAT: '👒',
  WATCH: '⌚️',
  TOP: '👚',
  OUTERWEAR: '🧥',
  PANTS_OR_DRESS: '👖👗🗻',
  BAG: '👜',
  SHOES: '👢',
}
