import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useHistory, useLocation } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Fade from 'react-bootstrap/Fade'
import Spinner from 'react-bootstrap/Spinner'

import { useSelector } from '../../hooks/useSel'
import { selectAuthError } from '../../store/auth/selectors'
import { LocationState } from '../../types'
import { useValidateField, Field } from '../../hooks/useValidateField'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [resetPasswordSent, setRetPasswordSent] = useState(false)
  const [isSignInMethodLoading, setIsSignInMethodLoading] = useState(false)
  const [password, setPassword] = useState('')
  const { isValid: isEmailValid, isInvalid: isEmailInvalid } = useValidateField(Field.Email, email)
  const { isValid: isPswValid, isInvalid: isPswInvalid } = useValidateField(
    Field.Password,
    password
  )

  const history = useHistory()
  const location = useLocation<LocationState>()
  const { from } = location.state || { from: { pathname: '/' } }
  const firebase = useFirebase()
  const error = useSelector(selectAuthError)
  const [providerMessage, setProviderMessage] = useState('')

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      if (isResetPassword) {
        firebase.auth().useDeviceLanguage()
        await firebase.resetPassword(email)
        setRetPasswordSent(true)
        return
      }

      await firebase.login({ email, password })
      history.replace(from.pathname)
    } catch (error) {
      setRetPasswordSent(false)
    }
  }

  const handleOnForgetPassword = () => {
    setIsResetPassword(!isResetPassword)
    setRetPasswordSent(false)
  }

  const handleEmailOnBlur = async () => {
    setIsSignInMethodLoading(true)

    try {
      const providers = email && (await firebase.auth().fetchSignInMethodsForEmail(email))
      setIsSignInMethodLoading(false)

      if (providers && providers.length && providers[0] !== 'password') {
        setProviderMessage(
          `You already have a linked account. Login with ${providers[0]} by using the button below.`
        )
      }
    } catch (error) {
      setIsSignInMethodLoading(false)
      console.error('From handleEmailOnBlur:', error)
    }
  }

  return (
    <>
      {!resetPasswordSent ? (
        <>
          <h2 className="txt-center">
            {!isResetPassword ? 'Welcome back!' : 'Oops. Forgot your password?'}
          </h2>
          <p className="p txt-center mb-30">
            {!isResetPassword
              ? `Your dream wardrobe is only a click away.`
              : 'Enter the email you signed up with so we can send you a link to reset it.'}
          </p>
        </>
      ) : (
        <p>
          {`Check your inbox we sent you a reset link to `}
          <span className="bold">{email}</span>
        </p>
      )}
      {!resetPasswordSent && (
        <Form onSubmit={handleOnSubmit}>
          <Form.Group controlId="formEmail" className="position-relative">
            <Form.Control
              type="email"
              placeholder="Email address"
              value={email}
              required
              onChange={(event) => setEmail(event.target.value)}
              onBlur={handleEmailOnBlur}
              isValid={!isSignInMethodLoading && isEmailValid && !error}
              isInvalid={isEmailInvalid || Boolean(error)}
            />

            {isSignInMethodLoading && (
              <Spinner variant={'info'} animation="border" size="sm" className="input-loader" />
            )}
          </Form.Group>

          {!isResetPassword && (
            <Fade in={!isResetPassword} appear>
              <Form.Group controlId="formPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="mb-0"
                  required
                  onChange={(event) => setPassword(event.target.value)}
                  isInvalid={isPswInvalid || Boolean(error)}
                  isValid={isPswValid && !error}
                />
              </Form.Group>
            </Fade>
          )}

          {error && (
            <Fade in={Boolean(error)} appear>
              <p className="p p-xs txt-td-red">{error?.message}</p>
            </Fade>
          )}
          {providerMessage && (
            <Fade in={Boolean(providerMessage)} appear>
              <p className="p p-xs txt-td-red">{providerMessage}</p>
            </Fade>
          )}
          <Button
            variant="secondary"
            type="submit"
            block
            disabled={Boolean(providerMessage) || !(isEmailValid && isPswValid)}
          >
            {!isResetPassword ? 'LOG IN' : 'SEND ME THE LINK!'}
          </Button>
        </Form>
      )}
      <span
        onClick={handleOnForgetPassword}
        className="txt-center mt-15 bold txt-td-pink cursor-pointer"
      >
        {!isResetPassword ? 'Forgot password?' : '< Back to login'}
      </span>
    </>
  )
}
