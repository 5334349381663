import imageCompression from 'browser-image-compression'
import React, { useCallback, useState } from 'react'
import { Button, Fade } from 'react-bootstrap'
import { useDropzone, FileRejection } from 'react-dropzone'
import { useFirebase } from 'react-redux-firebase'
import classNames from 'classnames'

import CloudUploadIcon from '../../assets/svg/ui-icons/cloud-upload.svg'
import UserIcon from '../../assets/svg/ui-icons/user.svg'
import { useAlert } from '../../hooks/useAlert'

export const Profile = () => {
  const firebase = useFirebase()
  const user = firebase.auth().currentUser
  const [displayName, setDisplayName] = useState(user?.displayName ?? '')
  const [showFileUploader, setShowFileUploader] = useState(false)
  const [isImageUploading, setIsImageUploading] = useState(false)
  const { setAlert } = useAlert()

  const handleVerifyAccount = async () => {
    try {
      await user?.sendEmailVerification({
        url: 'https://web.thedrobe.com/account/profile',
      })
      setAlert({
        text: 'We sent a verification link to your inbox! Check the junk folder too 📨',
        show: true,
        variant: 'success',
        duration: 6000,
      })
    } catch (error) {
      setAlert({
        text: error?.message ?? 'Something went wrong when verifying your account',
        variant: 'danger',
        show: true,
      })
    }
  }

  const handleOnBlurDisplayName = async () => {
    if (displayName === user?.displayName) {
      // If the displayName hasn't changed don't go in the try block
      return
    }

    try {
      await user?.updateProfile({ displayName })
      setAlert({ variant: 'success', text: `Full name updated`, show: true })
    } catch (error) {
      setAlert({
        text: error?.message ?? 'Something when updating your full name',
        variant: 'danger',
        show: true,
      })
    }
  }

  const handleOnDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setIsImageUploading(true)
      if (fileRejections.length) {
        // If there are any rejections grab the first error and end handleOnDrop
        setAlert({
          text: fileRejections[0].errors[0].message,
          variant: 'danger',
          show: true,
        })
        return
      }
      try {
        // Grabs the added image, optimases it and generates a unique filename
        const rawImage = acceptedFiles[0]
        const compressedImage = await imageCompression(rawImage, {
          maxSizeMB: 1,
          maxWidthOrHeight: 768,
          useWebWorker: true,
        })
        const optimisedImage = new File([compressedImage], `${Date.now()}_${rawImage.name}`, {
          type: rawImage.type,
        })

        // Uploads data to be Firebase
        const { uploadTaskSnapshot } = await firebase.uploadFile(
          `${user?.uid}/avatar`,
          optimisedImage
        )
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()

        // Assign uploaded image to user profile
        await user?.updateProfile({
          photoURL: downloadURL,
        })

        setIsImageUploading(false)
        setAlert({
          text: 'Image updated',
          variant: 'success',
          show: true,
        })
      } catch (error) {
        setIsImageUploading(false)
        setAlert({
          text: error?.message ?? 'Something went wrong when editing your image',
          variant: 'danger',
          show: true,
        })
      }
    },
    [user, firebase, setAlert]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
    maxSize: 2097152, //2MB
    multiple: false,
    onDrop: handleOnDrop,
  })

  return (
    <div>
      <div className="p-15 flex jc-space-between">
        <h3 className="h3">
          Profile {!user?.emailVerified && <span className="txt-td-red">needs verification</span>}
        </h3>

        {!user?.emailVerified && (
          <Button className="btn-td" variant="outline-secondary" onClick={handleVerifyAccount}>
            Verify account
          </Button>
        )}
      </div>
      <div className="bg-offwhite horizontal-line" />

      <div className="flex flex-col jc-center ai-center">
        <div className="flex mt-30 jc-space-between col-7">
          <div className="flex flex-col">
            <label className="editor-label">Avatar</label>
            <div
              className="profile-image br-primary mb-30 flex overflow-hidden"
              style={{ backgroundImage: `url(${user?.photoURL ? user?.photoURL : UserIcon})` }}
              onMouseEnter={() => setShowFileUploader(!showFileUploader)}
              onMouseLeave={() => setShowFileUploader(!showFileUploader)}
              onFocus={() => console.log('fsdaf')}
            >
              {isImageUploading && (
                <div className="flex flex-col flex-one ai-center jc-center bg-td-offwhite">
                  <h2 className="editor-uploader-icon">☁️</h2>
                  <h3 className="editor-uploader-text txt-td-pink">UPLOADING</h3>
                </div>
              )}
              <Fade in={showFileUploader && !isImageUploading}>
                <div
                  {...getRootProps({
                    className: classNames([
                      'flex flex-one jc-center ai-center file-uploader',
                      { 'position-absolute ': isImageUploading },
                    ]),
                  })}
                >
                  <input {...getInputProps()} disabled={isImageUploading} />
                  <div className="flex flex-col ai-center jc-center p-15">
                    <img className="editor-image-upload-icon" src={CloudUploadIcon} alt="Upload" />
                    <h6 className="txt-offwhite txt-center">
                      Click or drag and drop an image here
                    </h6>
                  </div>
                </div>
              </Fade>
            </div>
          </div>

          <div className="flex flex-col col-6">
            <label className="editor-label">Full name</label>
            <input
              type="text"
              className="mb-30 editor-title"
              value={displayName}
              onBlur={handleOnBlurDisplayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <label className="editor-label">Email address (not editable)</label>
            <input type="text" readOnly className="mb-30 editor-title" value={user?.email ?? ''} />
          </div>
        </div>

        {/* <div className="mt-30 col-7">
          <h4 className="h4 txt-td-pink">Membership</h4>
          <div className="flex">
            <label className="editor-label">Type</label>
            <p className="txt-td-dgrey">Lifetime 🔑</p>
          </div>

          <div className="flex">
            <label className="editor-label">Expires</label>
            <p className="txt-td-dgrey">Never</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}
