import React from 'react'

export const PaletteIcon = () => (
  <svg
    className="ui-icon colour-palette"
    width="26"
    height="25.999"
    viewBox="0 0 26 25.999"
  >
    <path
      d="M51.016,67a2.3,2.3,0,0,0,1.75-.75,2.13,2.13,0,0,0-.343-3.28l-2.051-1.821a6.592,6.592,0,0,1,0-10.1A13.017,13.017,0,0,1,59.025,48a16.362,16.362,0,0,1,10.75,4.069,11.863,11.863,0,0,1,0,18.179A15.849,15.849,0,0,1,59.422,74h-.115a15.285,15.285,0,0,1-10.075-3.548c-1.216-1.076-1.216-1.388-.005-3.228C49.516,66.75,50.078,67.063,51.016,67Zm19-9a2,2,0,1,0-2,2A2,2,0,0,0,70.016,58Zm-2.5,8.438a2,2,0,1,0-2-2A2,2,0,0,0,67.516,66.438ZM65.016,54a2,2,0,1,0-2,2A2,2,0,0,0,65.016,54Zm-4,16.938a3,3,0,1,0-3-3A3,3,0,0,0,61.016,70.938ZM56.516,56a2,2,0,1,0-2-2A2,2,0,0,0,56.516,56Z"
      transform="translate(-48.015 -48)"
      fill="#a2999a"
    />
  </svg>
)
