import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { firebaseReducer, FirebaseReducer, getFirebase } from 'react-redux-firebase'

import { uiReducer, UiReducer } from './ui/reducers'
import { PaletteType, SubscriptionType, WardrobeItemType, WardobeCategory } from '../types'
import { OutfitMapType } from '../types/outfits'
import { ReferralType } from '../types/referrals'

export type AppState = ReturnType<typeof rootReducer>

interface Profile {
  name: string
  email: string
  isPremium?: boolean
  platform?: 'web' | 'ios' | 'android'
  showOnboarding?: boolean
  referredBy?: string
  referralLink?: string
}

interface Schema {
  subscriptions: SubscriptionType
  outfits: OutfitMapType
  palettes: PaletteType[]
  referrals: ReferralType
  wardrobe: { [key in WardobeCategory]: { [itemId: string]: WardrobeItemType } }
}

export interface RootState {
  firebase: FirebaseReducer.Reducer<Profile, Schema>
  ui: UiReducer
}

const rootReducer = combineReducers<RootState>({
  firebase: firebaseReducer,
  ui: uiReducer,
})

export const configureStore = () => {
  const middlewares = [thunkMiddleware.withExtraArgument(getFirebase)]
  const middleWareEnhancer = applyMiddleware(...middlewares)

  const store = createStore(rootReducer, composeWithDevTools(middleWareEnhancer))

  return store
}
