import { useEffect, useState, useCallback } from 'react'

import { WardrobeImageType } from '../types'

export const usePreloadImage = (image: WardrobeImageType) => {
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [hasImageFailed, setHasImageFailed] = useState(false)

  const handleOnImageLoaded = () => {
    setIsImageLoading(false)
    setHasImageFailed(false)
  }

  const handleOnError = () => {
    setHasImageFailed(true)
    setIsImageLoading(false)
  }

  const preloadImage = useCallback((src: string) => {
    const img = new Image()
    img.onload = handleOnImageLoaded
    img.src = src
    img.onerror = handleOnError
  }, [])

  useEffect(() => {
    if (image?.url) {
      setIsImageLoading(true)
      preloadImage(image?.url)
    }
    // disabling eslint as it's asking for the full image object
    // eslint-disable-next-line
  }, [image?.url, preloadImage])

  return { isImageLoading, hasImageFailed }
}
