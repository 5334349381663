import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Fade from 'react-bootstrap/Fade'
import classNames from 'classnames'

import { WardrobeItemType, WardrobeImageType, WardrobeRouteParams } from '../../types'
import { WardrobeItemPlaceholder } from './WardrobeItemPlaceholder'
import { usePreloadImage } from '../../hooks/usePreloadImage'
import { ROUTE_PATHS } from '../Routes'
import { useScrollIntoView } from '../../hooks/useScrollIntoView'

interface Props {
  itemId: string
  item: WardrobeItemType
  disabled?: boolean
}

export const WardrobeItem = ({
  disabled = false,
  itemId,
  item: { image, category, colour },
}: Props) => {
  const { scrollRef, scrollRefIntoView } = useScrollIntoView<HTMLAnchorElement>()
  const queryItemId = useParams<WardrobeRouteParams>().itemId
  const [itemImage, setItemImage] = useState<WardrobeImageType>(image)
  const { isImageLoading, hasImageFailed } = usePreloadImage(itemImage)
  const catLowercase = category.toLowerCase()
  const imgSize =
    catLowercase === 'pants' || catLowercase === 'dresses' || catLowercase === 'outerwear'
      ? 'wardrobe-image--portrait'
      : 'wardrobe-image--square'
  const imgLoader = isImageLoading && 'wardrobe-image-loader bg-td-offwhite'
  const linkTo = disabled
    ? `${ROUTE_PATHS.WARDROBE}/${catLowercase}`
    : `${ROUTE_PATHS.WARDROBE}/${catLowercase}/${itemId}`
  const isSelected = queryItemId === itemId && 'wadrobe-item--selected'
  const isDisabled = disabled && 'wadrobe-item--disabled'

  useEffect(() => {
    setItemImage(image)
  }, [image])

  if (itemImage && !hasImageFailed) {
    return (
      <Link
        ref={scrollRef}
        onClick={scrollRefIntoView}
        to={linkTo}
        className={classNames([
          'wadrobe-item wardrobe-image m-15 br-primary bs-secondary',
          imgSize,
          imgLoader,
          isSelected,
          isDisabled,
        ])}
        style={{
          backgroundImage: `url(${itemImage?.url})`,
        }}
      >
        {isImageLoading && <WardrobeItemPlaceholder isLoading={isImageLoading} colour={colour} />}
      </Link>
    )
  }

  return (
    <Fade in={!isImageLoading}>
      <Link
        ref={scrollRef}
        onClick={scrollRefIntoView}
        to={linkTo}
        className={classNames([
          'wadrobe-item wardrobe-colour m-15 br-primary bs-secondary',
          imgSize,
          isSelected,
          isDisabled,
        ])}
        style={{
          backgroundColor: colour,
        }}
      >
        <WardrobeItemPlaceholder colour={colour} />
      </Link>
    </Fade>
  )
}
