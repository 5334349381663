import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ROUTE_PATHS } from '../Routes'
import { PaletteEditor } from './PaletteEditor'
import { WardrobeEditor } from './WardrobeEditor'
import { OutfitEditor } from './OutfitEditor'

export const Editor = () => {
  const { path } = useRouteMatch()

  if (path === ROUTE_PATHS.PALETTES) {
    return <PaletteEditor />
  }

  if (path.includes(ROUTE_PATHS.WARDROBE)) {
    return <WardrobeEditor />
  }

  if (path.includes(ROUTE_PATHS.OUTFITS)) {
    return <OutfitEditor />
  }

  return null
}
