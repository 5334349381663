import firebase from 'firebase/compat/app'

export * from './palettes'
export * from './wardrobe'
export * from './outfits'

export const TIMESTAMP = firebase.database.ServerValue.TIMESTAMP

export const DB_PATHS = {
  OUTFITS: 'outfits',
  PALETTES: 'palettes',
  REFERRALS: 'referrals',
  SUBSCRIPTIONS: 'subscriptions',
  USERS: 'users',
  WARDROBE: 'wardrobe',
}

export const COLOURS = {
  TD_BLK: '#353333',
  TD_OFFWHITE: '#FAFAFA',
  TD_LIGHT_BLUE: '#BCE0FD',
}
