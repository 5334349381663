import React, { useEffect } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { isEmpty, isLoaded } from 'react-redux-firebase'

import { Loader } from '../Loader'
import { LocationState } from '../../types'
import { useSelector } from '../../hooks/useSel'
import { MobileAppDownloadBtns } from '../MobileAppDownloadBtns'
import iPhoneImage from '../../assets/images/iphone-the-drobe.png'
import TDlogo from '../../assets/svg/the-drobe-logo-horizontal-variant.svg'
import { selectCurrentUser } from '../../store/auth/selectors'
import { SocialAuth } from './SocialAuth'
import { Login } from './Login'
import { ROUTE_PATHS } from '../Routes'
import { SignUp } from './SignUp'

export const Auth = () => {
  const history = useHistory()
  const location = useLocation<LocationState>()
  const { from } = location.state || { from: { pathname: '/' } }
  const currentUser = useSelector(selectCurrentUser)
  const isLogin = location.pathname === ROUTE_PATHS.LOGIN
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      history.replace(from.pathname)
    }
  }, [currentUser, from.pathname, history])

  if (!isLoaded(currentUser)) {
    return <Loader fullScreen />
  }

  if (isEmpty(currentUser)) {
    return (
      <div className="container position-relative">
        <div className="position-absolute overflow-hidden login-bg" />
        <div className="flex row jc-center mt-50 mb-50">
          <img src={TDlogo} alt="The 'drobe logo - Horizontal variant" />
        </div>
        <div className="flex row ai-flex-start jc-center mt-15">
          <div className="col-3 mr-50">
            <img src={iPhoneImage} alt="The 'drobe app" className="img-fluid" />
          </div>
          <div className="col-4">
            <div className="bs-primary br-primary p-30 flex flex-col bg-white mb-15">
              {isLogin ? <Login /> : <SignUp />}

              <SocialAuth />
            </div>
            <div className="bs-primary br-primary p-30 flex flex-col bg-white">
              <p className="p txt-center txt-td-grey m-0">
                {isLogin ? `Don't have an account yet? ` : `Have an account? `}
                <Link to={isLogin ? ROUTE_PATHS.SIGN_UP : ROUTE_PATHS.LOGIN} className="bold">
                  {isLogin ? 'Sign up' : 'Log in'}
                </Link>
              </p>
            </div>
            <div className="flex flex-col mt-30 jc-center ai-center">
              <p className="p txt-center bold txt-td-grey">{`Download the free mobile app.`}</p>
              <MobileAppDownloadBtns />
            </div>
          </div>
        </div>
        <div className="txt-td-grey mt-50">
          <p>
            © {currentYear} The ’drobe. All rights reserved.{' '}
            <a target="_blank" href="https://thedrobe.com/privacy-policy">
              Privacy Policy
            </a>
            {' - '}
            <a target="_blank" href="https://thedrobe.com/terms-of-use">
              Terms of Use
            </a>
          </p>
        </div>
      </div>
    )
  }

  return null
}
