export const firebaseConfig = {
  apiKey: 'AIzaSyBXJR_QrC99p4u4Vhh1pQCarmzXlq7kA_I',
  authDomain: 'the-drobe.firebaseapp.com',
  databaseURL: 'https://the-drobe.firebaseio.com',
  projectId: 'the-drobe',
  storageBucket: 'the-drobe.appspot.com',
  messagingSenderId: '88070482764',
  appId: '1:88070482764:web:192a0f103ef3dae882bde9',
  measurementId: 'G-WBM3NLBJ39',
}

export const rrfConfig = {
  userProfile: 'users',
}
