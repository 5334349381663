import React, { useEffect, useState, useMemo } from 'react'
import { useFirebaseConnect, isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { AddBtn } from '../AddBtn'
import { DB_PATHS, MAX_WARDROBE_ITEMS } from '../../constants'
import { useSelector } from '../../hooks/useSelector'
import { useSelector as useCustomSelector } from '../../hooks/useSel'
import { selectUid } from '../../store/auth/selectors'
import { Loader } from '../Loader'
import { wardrobeEmojiMap, wardrobeList, defaultEmptyItem } from '../../data/wardrobe'
import {
  WardobeCategory,
  WardrobeItemType,
  WardrobeRouteParams,
  OrderedWardrobe,
  AlertType,
} from '../../types'
import { Editor } from '../Editor'
import { selectOrderedWardrobe, selectWardrobeItemCount } from '../../store/wardrobe/selectors'
import { WardrobeItem } from './WardrobeItem'
import { EmptyState } from '../EmptyState'
import { ROUTE_PATHS } from '../Routes'
import { CustomModal } from '../CustomModal'
import { makeSelectIsSubActive } from '../../store/subscription/selectors'
import { limitReached, LIMIT_REACHED, LimitReachedType, generateRandomColour } from '../../helpers'
import { useAlert } from '../../hooks/useAlert'
import { ItemsCount } from '../ItemsCount'
import { selectUIAlert } from '../../store/ui/selectors'
import { selectReferralsInfo } from '../../store/referrals/selectors'

export const Wardrobe = React.memo(() => {
  const firebase = useFirebase()
  const { category, itemId } = useParams<WardrobeRouteParams>()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [newItem, setNewItem] = useState(false)
  const [newItemCategory, setNewItemCategory] = useState<WardobeCategory | '' | undefined>(
    category as WardobeCategory
  )
  const [activeKey, setActiveKey] = useState(category)
  const uid = useSelector(selectUid) as string
  const isSubActive = useSelector(makeSelectIsSubActive) as boolean
  const wardrobeItemCount = useSelector(selectWardrobeItemCount) as number
  const wardrobe = useSelector(selectOrderedWardrobe) as OrderedWardrobe
  const referral = useCustomSelector(selectReferralsInfo)
  const maxWardrobeItems =
    referral && referral?.wardrobe ? MAX_WARDROBE_ITEMS + referral.wardrobe : MAX_WARDROBE_ITEMS
  const modalContent = limitReached(LIMIT_REACHED.WARDROBE as LimitReachedType, maxWardrobeItems)
  const { setAlert } = useAlert()
  const alert = useSelector(selectUIAlert) as AlertType

  const isValidPathCategory = useMemo(
    () =>
      wardrobe &&
      wardrobe[uid]?.length &&
      !wardrobe[uid].some(({ key }) => category && category === key),
    [wardrobe, uid, category]
  )
  const isValidPathItemId = useMemo(
    () =>
      wardrobe &&
      wardrobe[uid]?.length &&
      !wardrobe[uid].some(({ value }) => Object.keys(value).some((el) => itemId && itemId === el)),
    [wardrobe, uid, itemId]
  )

  useFirebaseConnect([
    { path: `${DB_PATHS.WARDROBE}/${uid}` },
    { path: `${DB_PATHS.REFERRALS}/${uid}` },
  ])

  const handleTabsOnSelect = (tabKey: string) => {
    setActiveKey(tabKey)
    history.replace(`${ROUTE_PATHS.WARDROBE}/${tabKey}`)
  }

  const handleAddItemBtn = () => {
    if (!isSubActive && wardrobeItemCount >= maxWardrobeItems) {
      return setShowModal(true)
    }

    setShowModal(true)
    setNewItem(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
    setNewItemCategory(category as WardobeCategory)
  }

  const handleOnAddItem = async () => {
    if (newItemCategory) {
      const payload: WardrobeItemType = {
        ...defaultEmptyItem,
        colour: generateRandomColour(),
        category: newItemCategory,
      }

      try {
        const savedItemSnapshot = await firebase.push(
          `${DB_PATHS.WARDROBE}/${uid}/${newItemCategory}`,
          payload
        )

        if (savedItemSnapshot.key) {
          history.replace(`${ROUTE_PATHS.WARDROBE}/${newItemCategory}/${savedItemSnapshot.key}`)
          setActiveKey(newItemCategory)
          setShowModal(false)
          setAlert({ text: 'Item created', variant: 'success', show: true })
        }
      } catch (error) {
        if (error instanceof Error) {
          setAlert({ text: error.message, variant: 'danger', show: true })
        }
      }
    }
  }

  useEffect(() => {
    // Assign activeKey to first element and match the url path is a category doesn't exist
    if (wardrobe && wardrobe[uid]?.length) {
      if (isValidPathCategory && !itemId) {
        setActiveKey(wardrobe[uid][0].key)
        history.replace(`${ROUTE_PATHS.WARDROBE}/${wardrobe[uid][0].key}`)
      }

      if (itemId && isValidPathItemId) {
        history.replace(`${ROUTE_PATHS.WARDROBE}/${category}`)
      }
    }

    if (wardrobe && !wardrobe[uid] && category) {
      history.replace(ROUTE_PATHS.WARDROBE)
    }
  }, [wardrobe, uid, category, history, itemId, isValidPathItemId, isValidPathCategory])

  useEffect(() => {
    if (category) {
      setNewItemCategory(category as WardobeCategory)
    }

    return () => setNewItemCategory('')
  }, [category])

  const renderWardrobeItemList = useMemo(() => {
    if (wardrobe && wardrobe[uid]) {
      return wardrobe[uid].map(({ key, value }) => {
        return (
          <Tab
            key={key}
            eventKey={key}
            title={`${key} ${wardrobeEmojiMap[key as WardobeCategory]}`}
          >
            <div className="tab-content-inner">
              {Object.keys(value).map((el, i) => {
                const isDisabled =
                  maxWardrobeItems <= wardrobeItemCount &&
                  Math.ceil((wardrobeItemCount - maxWardrobeItems) / wardrobe[uid].length) < i + 1

                return (
                  <WardrobeItem
                    key={i}
                    itemId={el}
                    item={value[el]}
                    disabled={isSubActive ? false : isDisabled}
                  />
                )
              })}
            </div>
          </Tab>
        )
      })
    }
    return null
  }, [isSubActive, wardrobe, uid, wardrobeItemCount, maxWardrobeItems])

  const renderNewItemModalContent = () => (
    <Form.Group className="form-control-select">
      <Form.Control
        as="select"
        onChange={(e) => setNewItemCategory(e.target.value as WardobeCategory)}
        defaultValue={newItemCategory || ''}
      >
        <option disabled value={''}>
          Choose a category
        </option>
        {wardrobeList.category.map(({ label }, i) => (
          <option key={i} value={label.toLowerCase()}>
            {label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )

  const renderCustomModal = () => (
    <CustomModal
      show={showModal}
      onModalClose={handleModalClose}
      onModalConfirm={newItem ? handleOnAddItem : () => setShowModal(false)}
      body={newItem ? renderNewItemModalContent() : modalContent.body}
      title={newItem ? 'Add a new item' : modalContent.title}
      ctaDisabled={newItem && !newItemCategory}
      ctaLabel={newItem ? 'Add' : 'Got it'}
    />
  )

  if (!isLoaded(wardrobe)) {
    return <Loader fullScreen />
  }

  if (isEmpty(wardrobe[uid])) {
    return (
      <>
        {renderCustomModal()}
        <EmptyState isWardrobe onClick={handleAddItemBtn} />
      </>
    )
  }

  return (
    <>
      {renderCustomModal()}
      <div className="section-container bg-offwhite flex flex-row">
        <div className="section-items flex flex-col">
          <div className="section-container-heading pl-50 pt-30 pr-50 pb-15">
            <h1 className="flex ai-flex-start">
              Wardrobe{' '}
              {!isSubActive && (
                <ItemsCount
                  tooltip={'Items used with free plan'}
                  current={wardrobeItemCount}
                  max={maxWardrobeItems}
                />
              )}
            </h1>
            <Alert className="m-0" variant={alert?.variant} show={alert?.show}>
              {alert?.text}
            </Alert>
            <AddBtn onClick={handleAddItemBtn} label={'Add item'} />
          </div>

          <div className="tabs-container mt-15 mr-50 mb-50 ml-50">
            <Tabs
              defaultActiveKey={activeKey || wardrobe[uid][0].key}
              id="wardrobe-tabs"
              className="tabs-navigator"
              onSelect={handleTabsOnSelect}
              activeKey={activeKey}
            >
              {renderWardrobeItemList}
            </Tabs>
          </div>
        </div>

        {category && itemId && <Editor />}
      </div>
    </>
  )
})

Wardrobe.displayName = 'Wardrobe'
