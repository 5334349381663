import React, { useState, useEffect } from 'react'
import { HuePicker, ColorResult } from 'react-color'
import { Saturation } from 'react-color/lib/components/common'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import tinycolor from 'tinycolor2'
import Form from 'react-bootstrap/Form'
import classNames from 'classnames'

import { PointerCircle } from './PointerCircle'
import { DeleteBtn } from '../DeleteBtn'
import { EditorImageUploader } from './EditorImageUploader'
import { useSelector } from '../../hooks/useSel'
import { selectUid } from '../../store/auth/selectors'
import { WardobeCategory } from '../../types'
import { makeSelectWardrobeItemByIdAndCat } from '../../store/wardrobe/selectors'
import { useAlert } from '../../hooks/useAlert'
import { DB_PATHS, TIMESTAMP } from '../../constants'
import { CustomModal } from '../CustomModal'
import { ROUTE_PATHS } from '../Routes'

interface MatchParams {
  itemId: string
  category: WardobeCategory
}

export const WardrobeEditor = () => {
  const {
    params: { category, itemId },
  } = useRouteMatch<MatchParams>()
  const history = useHistory()
  const wardrobeItem = useSelector(makeSelectWardrobeItemByIdAndCat(itemId, category))
  const firebase = useFirebase()
  const uid = useSelector(selectUid)
  const { alert, setAlert } = useAlert()
  const [hsv, setHsv] = useState({ h: 0, s: 0, v: 0, a: 1 })
  const [showModal, setShowModal] = useState(false)
  const [colour, setColour] = useState('')
  const [notes, setNotes] = useState('')
  const noteCounter =
    notes.length <= 75 ? 'text-success' : notes.length <= 125 ? 'text-info' : 'text-danger'
  const saturationProps = {
    hsl: tinycolor(colour).toHsl(),
    hsv,
  }

  const handleHexOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColour(e.target.value)
    setHsv(tinycolor(e.target.value).toHsv())
  }

  const handleHueOnChange = (newColour: ColorResult) => saveUpdatedColour(newColour.hex)
  const handleHexOnBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== wardrobeItem.colour) {
      await saveUpdatedColour(colour)
    }
  }

  const handleNotesOnBlur = async (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (e.target.value !== wardrobeItem.notes) {
      await firebase.update(`${DB_PATHS.WARDROBE}/${uid}/${category}/${itemId}`, { notes })

      setAlert({ ...alert, text: 'Notes updated', show: true })
    }
  }

  const handleSaturationOnChange = async (newColour: any) => {
    const newHexColour = tinycolor(newColour).toHexString()
    setColour(newHexColour)

    newColour.source === 'hsv' && setHsv(newColour)

    await saveUpdatedColour(newHexColour)
  }

  const saveUpdatedColour = async (colour: string) => {
    await firebase.update(`${DB_PATHS.WARDROBE}/${uid}/${category}/${itemId}`, {
      colour,
      updatedAt: TIMESTAMP,
    })

    setAlert({ ...alert, text: `Colour updated to ${colour.toUpperCase()}`, show: true })
  }

  const handleDeleteBtn = () => setShowModal(true)

  const handleDeleteItem = () => {
    firebase.remove(`${DB_PATHS.WARDROBE}/${uid}/${category}/${itemId}`)

    if (wardrobeItem.image) {
      firebase.deleteFile(`${uid}/${DB_PATHS.WARDROBE}/${wardrobeItem.image?.name}`)
    }

    setShowModal(false)
    history.push(`${ROUTE_PATHS.WARDROBE}/${category}`)
  }

  useEffect(() => {
    if (wardrobeItem) {
      setColour(wardrobeItem.colour)
      setHsv(tinycolor(wardrobeItem.colour).toHsv())
      setNotes(wardrobeItem.notes || '')
    }
  }, [wardrobeItem])

  if (itemId) {
    return (
      <>
        <div className="bg-white editor">
          <div className="flex flex-one flex-row jc-flex-end">
            <div className="flex-col">
              <DeleteBtn label={'Delete item'} onClick={handleDeleteBtn} />
            </div>
          </div>
          {itemId && (
            <EditorImageUploader
              setAlert={setAlert}
              category={category}
              itemId={itemId}
              uid={uid}
            />
          )}
          <div className="flex">
            <div>
              <h5 className="editor-label">HEX</h5>
              <input
                type="text"
                className="input-colour"
                value={colour}
                onBlur={handleHexOnBlur}
                onChange={handleHexOnChange}
              />
            </div>
            <div className="flex-one ml-30">
              <h5 className="editor-label">Saturation</h5>
              <div className="editor-saturation editor-saturation--small">
                <Saturation
                  color={colour}
                  {...saturationProps}
                  onChange={handleSaturationOnChange}
                  pointer={PointerCircle}
                />
              </div>
            </div>
          </div>
          <h5 className="editor-label mt-30">Hue</h5>
          <HuePicker
            color={colour}
            onChange={(newColour) => setColour(newColour.hex)}
            height={'16px'}
            width={'100%'}
            onChangeComplete={handleHueOnChange}
          />
          <h5 className="editor-label mt-30 flex ai-center">
            Notes{' '}
            <span className={classNames(['character-count mr-1', noteCounter])}>
              {notes.length}/150
            </span>
          </h5>
          <Form.Control
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            onBlur={handleNotesOnBlur}
            className="form-control-textarea"
            as={'textarea'}
            placeholder={'e.g. never worn, missing button, has stain...'}
            maxLength={150}
          />
        </div>

        <CustomModal
          show={showModal}
          onModalClose={() => setShowModal(false)}
          onModalConfirm={handleDeleteItem}
          size={'sm'}
          body={
            <p>
              Are you sure you want to delete this item?
              <br />
              <strong>{`This action can't be undone`}</strong> 😱
            </p>
          }
          title={'Delete item'}
          ctaLabel={'Delete it'}
          dismissLabel={'Keep it'}
          variant={'danger'}
        />
      </>
    )
  }

  return null
}
